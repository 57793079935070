/**
 * Load a route from origin to destination
 */
export default function(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
): Promise<google.maps.LatLngLiteral[]> {
  return new Promise((resolve, reject) => {
    const directionsService = new google.maps.DirectionsService();
    // eslint-disable-next-line
    const request = {
      origin,
      destination,
      travelMode: google.maps.TravelMode.DRIVING
    } as google.maps.DirectionsRequest;

    directionsService.route(request, (response, status) => {
      if (status !== google.maps.DirectionsStatus.OK) {
        reject(status);
      }

      resolve(response.routes[0].overview_path.map(point => point.toJSON()));
    });
  });
}
