import React from 'react';

import TextField from '@material-ui/core/TextField';

interface IProps {
  areaName: string | null;
  onChange: (String) => void;
}

const AreaName = ({onChange, areaName}: IProps): JSX.Element => (
  <TextField
    style={{marginBottom: '16px'}}
    label="Name"
    InputLabelProps={{
      shrink: true
    }}
    value={areaName ? areaName : ''}
    InputProps={{
      placeholder: 'Flächenname eintragen',
      onChange: (event): void => onChange(event.target.value)
    }}
    variant="outlined"
  />
);

export default AreaName;
