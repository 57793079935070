import {IRoute} from '../../interfaces/route';
import React from 'react';
import colors from '../../constants/colors'; // eslint-disable-line

interface IProps {
  show: boolean;
  mapCanvas: google.maps.Map | null;
  routes: IRoute[];
  onClick?: (destination: google.maps.LatLngLiteral) => void;
}

/**
 * Traffic routes on the map
 */
export default class extends React.Component<IProps> {
  /**
   * A click event listener
   */
  listener: google.maps.MapsEventListener | null = null;

  /**
   * The destination markers
   */
  markers = new Map<string, google.maps.Marker>();

  /**
   * The route lines
   */
  polylines = new Map<string, google.maps.Polyline>();

  /**
   * There are new props here
   */
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    const {mapCanvas, show, routes} = nextProps;

    if (!mapCanvas) {
      return;
    }

    mapCanvas.setOptions({draggableCursor: 'pointer'});

    if (!show) {
      if (this.listener) {
        this.listener.remove();
        this.listener = null;
      }
      mapCanvas.setOptions({draggableCursor: 'grab'});
      this.clearRoutes();
      return;
    }

    if (!routes.length) {
      this.clearRoutes();
    }

    if (!this.listener) {
      this.listener = google.maps.event.addListener(
        mapCanvas,
        'click',
        this.onMapClick.bind(this)
      );
    }

    routes.forEach((route: IRoute) => this.renderRoute(mapCanvas, route));
  }

  /**
   * Render a route
   */
  renderRoute(mapCanvas: google.maps.Map, route: IRoute): void {
    const routeId: string = JSON.stringify(route.destination);

    if (this.markers.has(routeId)) {
      return;
    }

    const marker: google.maps.Marker = new google.maps.Marker({
      map: mapCanvas,
      position: route.destination,
      clickable: false,
      icon: {
        url: './images/marker.png',
        size: new google.maps.Size(21, 30),
        scaledSize: new google.maps.Size(21, 30),
        anchor: new google.maps.Point(10, 30)
      }
    });

    const polyline: google.maps.Polyline = new google.maps.Polyline({
      map: mapCanvas,
      path: route.path,
      geodesic: true,
      strokeColor: colors.primaryColor,
      strokeOpacity: 0.5,
      strokeWeight: 5
    });

    this.markers.set(routeId, marker);
    this.polylines.set(routeId, polyline);
  }

  /**
   * Remove all the routes from the map
   */
  clearRoutes() {
    this.markers.forEach(marker => marker.setMap(null));
    this.polylines.forEach((polyline: any) => polyline.setMap(null));
    this.markers.clear();
    this.polylines.clear();
  }

  /**
   * When the map got clicked somewhere
   */
  onMapClick(event: google.maps.MouseEvent) {
    if (this.props.onClick) {
      this.props.onClick(event.latLng.toJSON());
    }
  }

  /**
   * Whether the component should update or not
   */
  shouldComponentUpdate(): boolean {
    return false;
  }

  /**
   * Render the Component
   */
  render(): null {
    return null;
  }
}
