import React from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {areaTypeIdSelector} from '../../selectors/area';
import {showLegendSelector} from '../../selectors/show-legend';
import {viewSelector} from '../../selectors/view';

import styles from './reachability.styl';
import Button from '../shared/button/button';
import closeLegend from '../../actions/close-legend';

import IsochronesLegend from './isochrones-legend';
import PoisLegend from '../shared/pois-legend';

import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import {ViewIds} from '../../constants/views';

/**
 * The traffic reachability view
 */
const Reachability = (): JSX.Element | null => {
  const areaTypeId = useSelector(areaTypeIdSelector);
  const showLegend = useSelector(showLegendSelector);
  const view = useSelector(viewSelector);
  const dispatch = useDispatch();

  const legendClass = clsx(styles.trafficReachability, styles.colorGrey);

  return (
    <>
      {showLegend && view === ViewIds.TrafficReachability && (
        <section className={legendClass}>
          <div className={styles.trafficReachabilityLegend}>
            <div className={styles.containerTopLegendReachability}>
              <div className={styles.trafficReachabilityLegendTtile}>
                In 10 Minuten erreichbar
              </div>
              <Button
                variant="icon"
                className={styles.trafficReachabilityLegendCloseButton}
                onClick={(): void => {
                  dispatch(closeLegend());
                }}>
                <CloseIcon className={styles.colorGrey} />
              </Button>
            </div>
            <IsochronesLegend />
            <PoisLegend
              areaTypeId={areaTypeId}
              className={styles.trafficReachabilityLegendPois}
            />
            <footer className={styles.trafficReachabilityAttribution}>
              Quelle: OpenTripPlanner, OpenStreetMap
            </footer>
          </div>
        </section>
      )}
    </>
  );
};

export default Reachability;
