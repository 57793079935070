/**
 * Get the center of a polygon / a lot of points
 *
 * inspired by @turf/centroid which doesn’t want to get imported.
 */
export default function(
  rawPoints: google.maps.LatLngLiteral[]
): google.maps.LatLngLiteral {
  const points: google.maps.LatLngLiteral[] = [...rawPoints];
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  const equalFirstAndLast: boolean =
    firstPoint.lat === lastPoint.lat && firstPoint.lng === lastPoint.lng;

  if (equalFirstAndLast) {
    points.pop();
  }

  const length: number = points.length;
  let latSum = 0;
  let lngSum = 0;

  points.forEach(point => {
    latSum += point.lat;
    lngSum += point.lng;
  });

  return {
    lat: latSum / length,
    lng: lngSum / length
  };
}
