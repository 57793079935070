import React, {useEffect, useState} from 'react'; // eslint-disable-line no-unused-vars, max-len

import {ViewIds} from '../../constants/views';

import WmsMapType from '../../libs/wms-map-type';

interface IProps {
  view: ViewIds;
  zoom: number | null;
  mapCanvas: google.maps.Map | null;
}

/**
 * A municipality WMS overlay over the map
 */
const MunicipalitiesOverlay = ({
  zoom,
  mapCanvas
}: IProps): JSX.Element | null => {
  /**
   * The actual overlay
   */
  const [overlay, setOverlay] = useState<WmsMapType | null>(null);

  const createOverlay = (map: google.maps.Map): void => {
    if (!overlay) {
      const currentOverlay = new WmsMapType('iÖR', {
        url: 'https://sg.geodatenzentrum.de/wms_vg250?',
        layers: [{id: 'vg250_li'}]
      });
      setOverlay(currentOverlay);
      currentOverlay.addToMap(map);
    }
  };

  const destroyOverlay = (map: google.maps.Map): void => {
    if (overlay) {
      overlay.removeFromMap(map);
      setOverlay(null);
    }
  };

  useEffect(() => {
    if (mapCanvas && mapCanvas.getZoom() >= 10) {
      createOverlay(mapCanvas);
    }

    return (): void => {
      if (mapCanvas) {
        destroyOverlay(mapCanvas);
      }
    };
  }, [mapCanvas]);

  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    if (!zoom || zoom < 10) {
      destroyOverlay(mapCanvas);
      return;
    }

    createOverlay(mapCanvas);
  }, [zoom]);

  return null;
};

export default MunicipalitiesOverlay;