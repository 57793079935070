import React, {useState} from 'react';

import getShapeBounds from '../../libs/get-shape-bounds';

import Canvas from './canvas';
import AreaShape from './area-shape';
import Isochrones from './isochrones';
import Pois from './pois';
import {useSelector} from 'react-redux';
import {areaSelector} from '../../selectors/area';
import {IStore} from '../../interfaces/store';

/**
 * The map container
 */
const MapReachability = (): JSX.Element | null => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const area = useSelector(areaSelector);
  const isochrones = useSelector((state: IStore) => state.isochrones);
  const pois = useSelector((state: IStore) => state.pois);

  if (!area.shape || !area.center) {
    return null;
  }

  const bounds = getShapeBounds(area.shape, 2);

  return (
    <div className="map">
      <Canvas
        bounds={bounds}
        center={area.center}
        zoom={14}
        printVersion={true}
        onInit={setMap}
      />

      <AreaShape mapCanvas={map} shape={area.shape} />

      <Isochrones mapCanvas={map} isochrones={isochrones} show={true} />

      <Pois mapCanvas={map} pois={pois} />
    </div>
  );
};

export default MapReachability;
