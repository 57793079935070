import React, {useState} from 'react';
import {ViewIds} from '../constants/views';
import {useSelector} from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// @ts-ignore
import mediaQueries from '../../styles/media-queries.json';

import Map from './map/map';
import AnalyzeMenu from './analyze-menu/analyze-menu';
import Information from './information/information-container';
import Ecology from './ecologies/ecology';
import TrafficReachability from './traffic/reachability';
import TrafficInfoOverlay from './traffic/traffic-info-overlay';
import PopulationDevelopment from './chart-views/population-development/population';
import WorkplaceDevelopment from './chart-views/workplace-development/workplace';
import FollowupCosts from './chart-views/followup-costs/followup-costs-container';
import LocationCompetition from './location-competition/location-competition';
import configurationStepNumber from '../selectors/configuration-step-number';
import Welcome from '../components/welcome/welcome';
import RecentDevelopment from '../components/recent-development/recent-development-container';

import Configuration from './configuration/configuration';
import {steps} from '../config/steps';

import styles from './app.styl';

/**
 * The interactive app container
 */
const AppInteractive = (): JSX.Element => {
  const configStep = useSelector(configurationStepNumber);

  const configurationIsDone = configStep === steps.done.number;
  const configurationIsInit = configStep === steps.municipality.number;
  const isDesktop = useMediaQuery(mediaQueries.big);
  const [hideMap, setHideMap] = useState(false);
  const [hideConfig, setHideConfig] = useState(false);

  return (
    <div className={styles.container}>
      {configurationIsInit && <Redirect to="/welcome" />}
      {/* Always render map on desktop, just hide it when not needed (when Recent Development is shown) */}
      {isDesktop && <Map hide={hideMap} />}
      <Switch>
        <Route path={'/config'}>
          <Route
            path={'/config/:configurationStep'}
            render={(): JSX.Element => {
              if (hideMap) {
                setHideMap(false);
              }
              if (hideConfig) {
                setHideConfig(false);
              }
              return <Configuration hide={hideConfig} />;
            }}
          />
          <Route
            exact
            path={'/config/municipality/recent'}
            render={(): JSX.Element => {
              setHideMap(true);
              if (!isDesktop) {
                setHideConfig(true);
              }
              return <RecentDevelopment />;
            }}
          />
        </Route>
        <Route path={'/views'}>
          {!configurationIsDone && <Redirect to={'/welcome'} />}
          <Route
            exact
            path={'/views'}
            render={(): void => {
              if (hideMap) {
                setHideMap(false);
              }
            }}
          />

          <Route
            exact
            path={`/views/:any`}
            render={(): void => {
              if (hideMap) {
                setHideMap(false);
              }
            }}
          />
          <Switch>
            <Route
              exact
              path={'/views/:viewId/info'}
              render={(): JSX.Element => {
                if (!hideMap) {
                  setHideMap(true);
                }
                return <Information />;
              }}
            />
            {/* Views with chart components  */}
            <Route
              exact
              path={`/views/${ViewIds.WorkplaceDevelopment}/charts`}
              render={(): JSX.Element => {
                setHideMap(true);
                return <WorkplaceDevelopment />;
              }}
            />
            <Route
              path={`/views/${ViewIds.PopulationDevelopment}/charts`}
              render={(): JSX.Element => {
                setHideMap(true);
                return <PopulationDevelopment />;
              }}
            />
            <Route
              exact
              path={`/views/${ViewIds.FollowupCosts}/charts`}
              render={(): JSX.Element => {
                setHideMap(true);
                return <FollowupCosts />;
              }}
            />
            {/* Views without chart components  */}
            <Route
              path={[
                `/views/${ViewIds.EcologyHighVoltageSupplyLines}`,
                `/views/${ViewIds.EcologyLandscapeProtection}`,
                `/views/${ViewIds.EcologySpeciesProtection}`
              ]}>
              <Ecology />
            </Route>
            <Route path={`/views/${ViewIds.TrafficReachability}`}>
              <TrafficReachability />
            </Route>
            <Route path={`/views/${ViewIds.TrafficEnvironment}`}>
              <TrafficInfoOverlay />
            </Route>
            <Route path={`/views/${ViewIds.LocationCompetition}`}>
              <LocationCompetition />
            </Route>
          </Switch>
          <AnalyzeMenu onSidebarTextShown={(): void => setHideMap(false)} />
        </Route>
        {configurationIsDone && <Redirect to="/views" />}
        <Route path={'/welcome'}>
          <Welcome />
        </Route>
      </Switch>
    </div>
  );
};

export default AppInteractive;
