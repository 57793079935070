import React from 'react';
import {connect} from 'react-redux';

import Chart from './chart';
import {colorMap} from '../../../constants/workplace-development';
import {PopulationCategories} from '../../../constants/population-development';

import {totalUnitsSelector} from '../../../selectors/area';
import {populationStructureSelector} from '../../../selectors/population-structure';

import {IYearValue} from '../../../interfaces/year-value';
import {IStore} from '../../../interfaces/store';

interface IStoreProps {
  chartData: Map<PopulationCategories, IYearValue[]> | null;
  totalApartments: number | null;
}

interface IProps {
  startYear?: number;
  efhSize: number;
  mfhSize: number;
  efhUnderAgeProportion: number;
  mfhUnderAgeProportion: number;
  efhProportion: number;
  print: boolean;
}

const PopulationEstimation = ({
  totalApartments,
  chartData,
  print
}: IProps & IStoreProps): JSX.Element => {
  return (
    <React.Fragment>
      <Chart
        totalApartments={totalApartments}
        data={chartData}
        yAxisTitle="Bewohner/innnen"
        yAxisSubtitle="Neubauwohnungen"
        colorMap={colorMap}
        print={print}
      />
    </React.Fragment>
  );
};

function mapStoreToProps(store: IStore, ownProps: IProps): IStoreProps {
  return {
    chartData: populationStructureSelector(store, {
      startYear: ownProps.startYear,
      efhSize: ownProps.efhSize,
      mfhSize: ownProps.mfhSize,
      efhUnderAgeProportion: ownProps.efhUnderAgeProportion,
      mfhUnderAgeProportion: ownProps.mfhUnderAgeProportion,
      efhProportion: ownProps.efhProportion
    }),
    totalApartments: totalUnitsSelector(store)
  };
}

export default connect(mapStoreToProps)(PopulationEstimation);
