import React from 'react';
import {IArea, IStore} from '../../../interfaces/store';
import {IAreaConfig, areaConfigsById} from '../../../constants/area-config';
import {connect} from 'react-redux';
import {
  areaSelector,
  areaConfigSelector,
  totalUnitsSelector
} from '../../../selectors/area';

interface IProps {
  startYear?: number;
  efhProportion: number;
  onStartYearChange: (startDate: number) => void;
  onEfhProportionChange: (density: number) => void;
}

interface IStoreProps {
  area: IArea;
  areaConfig: IAreaConfig | null;
  totalApartments: number;
}

const getEfhUnits = (totalAparments: number, efhProportion: number): number => {
  return Math.round(totalAparments * efhProportion);
};
const getMfhUnits = (totalAparments: number, efhProportion: number): number => {
  return Math.round(totalAparments * (1 - efhProportion));
};

const AssumptionDevelopment = ({
  area,
  areaConfig,
  efhProportion,
  totalApartments,
  startYear = new Date().getFullYear(),
  onStartYearChange,
  onEfhProportionChange
}: IProps & IStoreProps): JSX.Element | null => {
  if (!areaConfig || !area) {
    return null;
  }
  const areaDefaults = areaConfigsById[area.densityId || 0];

  const onEfhProportionInputChange = (efhUnits: number): void => {
    onEfhProportionChange(efhUnits / totalApartments);
  };

  return (
    <div>
      <div className="summary">
        Ihre Projektdefinition ({area.size && area.size.toLocaleString('de')} ha
        Bruttowohnbauland x {areaConfig && areaConfig.density} Wohneinheiten /
        ha brutto) ergibt {totalApartments} Wohneinheiten im Plangebiet. In der
        nachstehenden Tabelle können Sie festlegen, wie viele Wohneinheiten
        davon in Ein- /Zweifamilien- und Reihenhäusern bzw. in
        Mehrfamilienhäusern entstehen sollen. Zusätzlich können Sie einstellen,
        in welchem Jahr der Erstbezug beginnen soll. Die Dauer des Erstbezugs
        haben Sie bereits bei der Projektdefinition zu Beginn festgelegt.
      </div>

      <table>
        <thead>
          <tr>
            <th> </th>
            <th>Annahme Projekt-Check</th>
            <th>Ihre Annahme</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Wohnungen in Ein- und Zweifamilien- sowie Reihenhäusern</td>
            <td>{getEfhUnits(totalApartments, areaDefaults.efhProportion)}</td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="0"
                max={totalApartments}
                value={getEfhUnits(totalApartments, efhProportion)}
                onChange={({target}): void =>
                  onEfhProportionInputChange(Number(target.value))
                }
              />
            </td>
          </tr>
          <tr>
            <td>Wohnungen in Mehrfamilienhäusern</td>
            <td>{getMfhUnits(totalApartments, areaDefaults.efhProportion)}</td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="0"
                max={totalApartments}
                value={getMfhUnits(totalApartments, efhProportion)}
                onChange={({target}): void =>
                  onEfhProportionInputChange(
                    Number(totalApartments - parseInt(target.value, 10))
                  )
                }
              />
            </td>
          </tr>
          <tr>
            <td>Beginn Erstbezug</td>
            <td>{new Date().getFullYear()}</td>
            <td>
              <input
                className="assumption-input"
                type="number"
                min="0"
                value={startYear}
                onChange={({target}): void =>
                  onStartYearChange(Number(target.value))
                }
              />
            </td>
          </tr>
          <tr>
            <td>Ende Erstbezug</td>
            <td>
              {new Date().getFullYear() + (area.influx ? area.influx : 0)}
            </td>
            <td className="assumption-non-input">
              {startYear <= 2050 && startYear >= 2010
                ? startYear + (area.influx ? area.influx : 0)
                : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
/**
 * Map the received store to properties that are passed to the component.
 */
const mapStoreToProps = (store: IStore): IStoreProps => ({
  area: areaSelector(store),
  areaConfig: areaConfigSelector(store),
  totalApartments: totalUnitsSelector(store)
});

export default connect(mapStoreToProps)(AssumptionDevelopment);
