/**
 * Style of the polygon
 */
export const polygonOptions = {
  clickable: false,
  fillColor: '#F5BB18',
  fillOpacity: 0.5,
  strokeColor: '#F5BB18',
  strokeOpacity: 1,
  strokeWeight: 3,
  zIndex: 2
};
