import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import Loading from '../../shared/loading/loading';
import PrintIntro from '../intro/intro';
import RecentDevelopment from '../recent-development/recent-development';
import ProjectDefinition from '../project-definition/project-definition';
import Population from '../population/population';
import Workplace from '../workplace/workplace';
import TrafficReachability from '../traffic/reachability';
import TrafficRoutes from '../traffic/routes';
import Ecologies from '../ecologies/ecologies';
import FollowupCosts from '../followup-costs/followup-costs';
import LocationCompetition from '../location-competition/location-competition';

import isPrintDataLoading from '../../../selectors/is-print-data-loading';
import fetchPrintData from '../../../actions/fetch-print-data';
import {importSettingsActionCreator} from '../../../actions/settings/import-settings';

import styles from './styles.styl';
import {AreaTypeIds} from '../../../constants/area-types';
import {areaConfigSelector} from '../../../selectors/area';

/**
 * The interactive app container
 */
const AppPrint = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPrintLoading = useSelector(isPrintDataLoading);
  const areaConfig = useSelector(areaConfigSelector);
  const [mapInit, setMapInit] = useState(false);

  useEffect(() => {
    try {
      // @ts-ignore
      const storageData = JSON.parse(window.localStorage.getItem('printData'));

      if (storageData === null) {
        throw Error;
      }

      dispatch(importSettingsActionCreator(storageData));
      dispatch(fetchPrintData());
      // Delete print data on print view open.
      // This might prevent caching and provides clean new print views every time.
      window.localStorage.removeItem('printData');
    } catch (error) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (!isPrintLoading && mapInit) {
      window.print();
    }
  }, [isPrintLoading, mapInit]);

  if (isPrintLoading || !areaConfig) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      {!mapInit && <Loading />}
      <PrintIntro />
      <ProjectDefinition onInit={(): void => setMapInit(true)} />
      <RecentDevelopment />
      {areaConfig?.areaTypeId === AreaTypeIds.Residential && <Population />}
      {areaConfig?.areaTypeId === AreaTypeIds.Industrial && <Workplace />}
      <TrafficReachability />
      <TrafficRoutes />
      <Ecologies />
      {[AreaTypeIds.Residential, AreaTypeIds.Industrial].includes(
        areaConfig.areaTypeId
      ) && <FollowupCosts />}
      {areaConfig?.areaTypeId === AreaTypeIds.Retail && <LocationCompetition />}
    </div>
  );
};

export default AppPrint;
