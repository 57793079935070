import React from 'react';
import {useSelector} from 'react-redux';

import MapTrafficRoutes from '../../map/map-traffic-routes';
import {areaSelector} from '../../../selectors/area';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import appStyles from '../app/styles.styl';

/**
 * The traffic routes print view
 */
const RoutesPrint = (): JSX.Element | null => {
  const area = useSelector(areaSelector);

  if (!area) {
    return null;
  }
  return (
    <PrintPage title="Verkehr im Umfeld">
      <PrintParagraph
        aside={
          <>
            <h3>
              Voraussichtliche Fahrtwege zum Plangebiet auf Basis des
              bestehenden Straßennetzes
            </h3>
            <p className={appStyles.source}>
              Routing berechnet auf Basis von Google Maps
            </p>
          </>
        }
        main={
          <>
            <p>Startpunkte gemäß Auswahl des Anwenders / der Anwenderin.</p>
            <MapTrafficRoutes />
          </>
        }
      />
    </PrintPage>
  );
};

export default RoutesPrint;
