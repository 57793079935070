import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {showLegendSelector} from '../../../selectors/show-legend';
import {viewSelector} from '../../../selectors/view';
import openLegend from '../../../actions/open-legend';
import {Ecologies} from '../../../constants/ecologies';
import {ViewIds} from '../../../constants/views';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LayersIcon from '@material-ui/icons/Layers';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import StyleIcon from '@material-ui/icons/Style';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// @ts-ignore
import mediaQueries from '../../../../styles/media-queries.json';

import {makeStyles} from '@material-ui/core/styles';
import styles from './map-type-controls.styl';
import clsx from 'clsx';

interface IProps {
  mapCanvas: google.maps.Map | null;
}
// eslint-disable-next-line complexity, max-statements
const MapTypeControls = ({mapCanvas}: IProps): JSX.Element | null => {
  if (!mapCanvas) {
    return null;
  }

  //Handle legend button
  const showLegend = useSelector(showLegendSelector);
  const view = useSelector(viewSelector);
  const dispatch = useDispatch();

  // Handle Maptypes
  const [currentMapType, setCurrentMapType] = useState(
    google.maps.MapTypeId.ROADMAP
  );

  useEffect(() => {
    mapCanvas.setMapTypeId(currentMapType);
  }, [currentMapType]);

  // Expand Maptype buttons
  const [expand, setExpand] = useState(true);
  const isDesktop = useMediaQuery(mediaQueries.big);
  const isLargeDesktop = useMediaQuery(mediaQueries.large);

  const isIphoneOrIpod = /iPhone|iPod/.test(navigator.platform);

  // Handle fullscreen mode
  const mapElement = document.querySelector('.map');

  const [fullScreen, setFullScreen] = useState(
    //eslint-disable-next-line dot-notation
    document.fullscreenElement || document['webkitFullScreenElement']
  );

  const fullscreenHandler = (): void => {
    setFullScreen(
      //eslint-disable-next-line dot-notation
      document.fullscreenElement || document['webkitFullScreenElement']
    );
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullscreenHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenHandler);
    return (): void => {
      document.removeEventListener('fullscreenchange', fullscreenHandler);
      document.removeEventListener('webkitfullscreenchange', fullscreenHandler);
    };
  }, []);

  /**
   * TS does not know any vendor specific functions/objects,
   * which is why we use square bracket notation here and
   * disable TS entirely in one line.
   */
  //eslint-disable-next-line complexity
  const toggleFullscreen = (): void => {
    if (!fullScreen && mapElement !== null) {
      const element = document.documentElement;
      if (element['requestFullscreen']) {
        element['requestFullscreen']();
      } else if (element['mozRequestFullScreen']) {
        element['mozRequestFullScreen']();
      } else if (element['webkitRequestFullscreen']) {
        // @ts-ignore
        element['webkitRequestFullscreen'](Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document['cancelFullScreen']) {
      document['cancelFullScreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitCancelFullScreen']) {
      document['webkitCancelFullScreen']();
    }
  };

  // Handle zoomControl button
  const [zoom, setZoom] = useState(mapCanvas.getZoom());

  useEffect(() => {
    mapCanvas.setZoom(zoom);
  }, [zoom]);

  const handlePlusZoom = (): void => {
    setZoom(zoom + 1);
  };

  const handleMinusZoom = (): void => {
    setZoom(zoom - 1);
  };

  // Styles
  makeStyles(
    {
      root: {
        position: 'absolute',
        flex: 1,
        width: '48px',
        padding: '8px',
        right: '8px',
        backgroundColor: 'white',
        borderRadius: '50%',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 4px 1px',
        '&:hover': {backgroundColor: 'white'},
        zIndex: 1
      }
    },
    {name: 'MuiIconButton'}
  )();

  const usecloseButtonStyles = makeStyles(theme => ({
    root: {
      position: 'relative',
      flex: 1,
      right: 0,
      width: '56px',
      padding: '8px 16px 8px 8px',
      borderRadius: 0,
      boxShadow: 'none',
      display: 'block',
      '-webkit-padding-start': '0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  }));

  const useLayersButtonStyles = makeStyles(theme => ({
    root: {
      top: 0,
      color: '#346E9F',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  }));

  const top =
    isLargeDesktop ||
    view === ViewIds.EcologyHighVoltageSupplyLines ||
    view === ViewIds.TrafficReachability ||
    view === ViewIds.LocationCompetition
      ? '16px'
      : '64px';

  const styleIconButtonStyles = makeStyles(theme => ({
    root: {
      top: '354px',
      color: '#346E9F',
      [theme.breakpoints.up('sm')]: {
        top,
        right: '25px'
      }
    }
  }));

  const fullScreenButtonStyles = makeStyles(theme => ({
    root: {
      top: '320px',
      position: 'static',
      marginTop: '28px',
      color: '#346E9F',
      [theme.breakpoints.up('sm')]: {
        top: 'auto',
        bottom: 25,
        borderRadius: 0,
        color: '#808080'
      },
      [theme.breakpoints.up('md')]: {
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 4px 1px'
      }
    }
  }));

  const useZoomPlusStyles = makeStyles(theme => ({
    root: {
      flex: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      position: 'static',
      color: '#808080',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  }));

  const useZoomMinusStyles = makeStyles(theme => ({
    root: {
      flex: 'none',
      borderRadius: 0,
      boxShadow: 'none',
      position: 'static',
      color: '#808080',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  }));

  const closeButtonClass = usecloseButtonStyles();
  const layersButtonClass = useLayersButtonStyles();
  const fullScreenButtonClass = fullScreenButtonStyles();
  const styleIconButtonClass = styleIconButtonStyles();
  const zoomPlusButtonClass = useZoomPlusStyles();
  const zoomMinusButtonClass = useZoomMinusStyles();

  return (
    <>
      <div
        className={clsx(
          styles.controlButtonContainer,
          showLegend &&
            !isDesktop &&
            styles.controlButtonContainerHeightLegendOpen
        )}>
        <div className={styles.zoomControlContainer}>
          <IconButton
            disableRipple={true}
            onClick={handlePlusZoom}
            classes={zoomPlusButtonClass}>
            <AddIcon fontSize="large" />
          </IconButton>
          <div className={styles.zoomButtonLine}></div>
          <IconButton
            disableRipple={true}
            onClick={handleMinusZoom}
            classes={zoomMinusButtonClass}>
            <RemoveIcon fontSize="large" />
          </IconButton>
        </div>
        {!isIphoneOrIpod && (
          <IconButton
            disableRipple={true}
            onClick={toggleFullscreen}
            classes={fullScreenButtonClass}>
            {!fullScreen ? (
              <FullscreenIcon fontSize="large" />
            ) : (
              <FullscreenExitIcon fontSize="large" />
            )}
          </IconButton>
        )}
      </div>
      <div className={clsx(
          styles.container,
          showLegend && !isDesktop && styles.containerHeightLegendOpen
        )}>
        {expand && !isDesktop ? (
          <IconButton
            disableRipple={true}
            onClick={(): void => {
              setExpand(!expand);
            }}
            classes={layersButtonClass}>
            <LayersIcon fontSize="large" />
          </IconButton>
        ) : (
          <div className={clsx(styles.buttonContainer, styles.roundedBorders)}>
            <button
              disabled={currentMapType === google.maps.MapTypeId.ROADMAP}
              onClick={(): void => {
                setCurrentMapType(google.maps.MapTypeId.ROADMAP);
              }}
              className={clsx(
                styles.button,
                styles.roundedBorders,
                currentMapType === google.maps.MapTypeId.ROADMAP &&
                  styles.active
              )}>
              Karte
            </button>
            <button
              disabled={currentMapType === google.maps.MapTypeId.HYBRID}
              onClick={(): void =>
                setCurrentMapType(google.maps.MapTypeId.HYBRID)
              }
              className={clsx(
                styles.button,
                currentMapType === google.maps.MapTypeId.HYBRID && styles.active
              )}>
              Satellit
            </button>
            <IconButton
              disableRipple={true}
              onClick={(): void => {
                setExpand(!expand);
              }}
              classes={closeButtonClass}
              aria-label="close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        )}
      </div>
      {!showLegend &&
        (Ecologies.has(view) ||
          view === ViewIds.TrafficReachability ||
          view === ViewIds.LocationCompetition) && (
          <IconButton
            disableRipple
            onClick={(): void => {
              dispatch(openLegend());
            }}
            classes={styleIconButtonClass}>
            <StyleIcon fontSize="large" />
          </IconButton>
        )}
    </>
  );
};

export default MapTypeControls;
