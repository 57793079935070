import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import {InputAdornment} from '@material-ui/core';

interface IProps {
  readonly size: number | null;
  onChange: (size: number | null) => void;
}

const AreaSize = ({size, onChange}: IProps): JSX.Element => {
  const [helperText, setHelperText] = useState('');

  const handleChange = (sizeInput: string): void => {
    const newSize = Number(sizeInput);
    if (newSize > 0) {
      setHelperText('');
      onChange(newSize);
    } else {
      setHelperText('Ungültige Eingabe.');
      onChange(null);
    }
  };

  return (
    <TextField
      error={helperText !== ''}
      label="Größe"
      InputProps={{
        endAdornment: <InputAdornment position="end">ha</InputAdornment>,
        onChange: (event): void => handleChange(event.target.value),
        type: 'number'
      }}
      InputLabelProps={{
        shrink: true
      }}
      helperText={helperText}
      defaultValue={size ? size : ''}
      variant="outlined"
    />
  );
};

export default AreaSize;
