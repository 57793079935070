import {IStore} from '../interfaces/store';
import {AreaTypeIds} from '../constants/area-types';
import {ViewIds} from '../constants/views';
import isEcologyView from './is-ecology-view';
import {IEcologyLayer} from '../interfaces/ecology';
import {IRoute} from '../interfaces/route';

interface IStoreProps {
  areaCenter: google.maps.LatLngLiteral | null;
  areaShape: google.maps.LatLngLiteral[] | null;
  areaTypeId: AreaTypeIds | null;
  ecologyLayers: Map<ViewIds, IEcologyLayer>;
  isochrones: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> | null;
  pois: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
  trafficRoutes: IRoute[];
  view: ViewIds;
  isEcologyView: boolean;
  bounds: google.maps.LatLngBoundsLiteral;
}

export default function(state: IStore): IStoreProps {
  return {
    areaCenter: state.area.center,
    areaShape: state.area.shape,
    areaTypeId: state.area.typeId,
    ecologyLayers: state.ecologyLayers,
    isochrones: state.isochrones,
    pois: state.pois,
    view: state.view,
    trafficRoutes: state.traffic.routes,
    isEcologyView: isEcologyView(state),
    bounds: state.map.bounds
  };
}
