import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Tab, Tabs} from '@material-ui/core';

import PopulationEstimation from './population-estimation';
import AssumptionDevelopment from './assumption-development';
import AssumptionPopulation from './assumption-population';

import {areaConfigSelector} from '../../../selectors/area';
import updateAreaConfig from '../../../actions/area/update-area-config';

import styles from '../chart-views.styl';

const title = 'Bewohner/innen';
const sectionTitles = {
  estimation: 'Schätzung',
  assumptionDevelopment: 'Annahme Bebauung',
  assumptionInhabitants: 'Annahme Haushalte'
};

/**
 * The population development of a municipality
 */
const PopulationDevelopment = (): JSX.Element | null => {
  const currentAreaConfig = useSelector(areaConfigSelector);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);

  if (!currentAreaConfig) {
    return null;
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div>
          <h1>{title}</h1>
        </div>
      </header>

      <div className={styles.tabContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_, newValue): void => {
            setSelectedTab(newValue);
          }}>
          <Tab disableRipple label={sectionTitles.estimation} />
          <Tab disableRipple label={sectionTitles.assumptionDevelopment} />
          <Tab disableRipple label={sectionTitles.assumptionInhabitants} />
        </Tabs>
      </div>
      <div className={styles.contentContainer}>
        {selectedTab === 0 && (
          <PopulationEstimation
            startYear={currentAreaConfig.startYear}
            efhSize={currentAreaConfig.efhSize}
            mfhSize={currentAreaConfig.mfhSize}
            efhUnderAgeProportion={currentAreaConfig.efhUnderAgeProportion}
            mfhUnderAgeProportion={currentAreaConfig.mfhUnderAgeProportion}
            efhProportion={currentAreaConfig.efhProportion}
            print={false}
          />
        )}
        {selectedTab === 1 && (
          <AssumptionDevelopment
            startYear={currentAreaConfig.startYear}
            efhProportion={currentAreaConfig.efhProportion}
            onEfhProportionChange={(efhProportion): void => {
              dispatch(updateAreaConfig({efhProportion}));
            }}
            onStartYearChange={(startYear): void => {
              dispatch(updateAreaConfig({startYear}));
            }}
          />
        )}
        {selectedTab === 2 && (
          <AssumptionPopulation
            efhSize={currentAreaConfig.efhSize}
            onEfhSizeChange={(efhSize): void => {
              dispatch(updateAreaConfig({efhSize}));
            }}
            mfhSize={currentAreaConfig.mfhSize}
            onMfhSizeChange={(mfhSize): void => {
              dispatch(updateAreaConfig({mfhSize}));
            }}
            efhUnderAgeProportion={currentAreaConfig.efhUnderAgeProportion}
            onEfhUnderAgeChange={(efhUnderAgeProportion): void => {
              dispatch(updateAreaConfig({efhUnderAgeProportion}));
            }}
            mfhUnderAgeProportion={currentAreaConfig.mfhUnderAgeProportion}
            onMfhUnderAgeChange={(mfhUnderAgeProportion): void => {
              dispatch(updateAreaConfig({mfhUnderAgeProportion}));
            }}
          />
        )}
      </div>
    </section>
  );
};

export default PopulationDevelopment;
