import React, {useState, useEffect} from 'react';

import {IMunicipalityData, IEmployed} from '../../interfaces/municipality-data';
import {IGraphDatum} from '../../interfaces/graph-datum';
import {IYearValue} from '../../interfaces/year-value';
import sizeme from 'react-sizeme';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Hint
} from 'react-vis';
import Legend from '../shared/legend';
import Loading from '../shared/loading/loading';
import createInvisibleLineData from '../../libs/create-invisible-line-data';
import clsx from 'clsx';
import colors from '../../constants/colors';
import styles from './recent-development.styl';

interface IEmployedGraphs {
  readonly inbound: IGraphDatum<number, number>[];
  readonly outbound: IGraphDatum<number, number>[];
  readonly inner: IGraphDatum<number, number>[];
}

interface IProps {
  data: IMunicipalityData | null;
  size: {width: number; height: number};
  print?: boolean;
}
interface IState {
  minYear: number;
  maxYear: number;
  domain: [number, number];
  tickTotal: number;
  graphs: IEmployedGraphs;
  invisibleLineData: IGraphDatum<number, number>[];
  hoveredValues: null | {
    readonly inbound: IGraphDatum<number, number> | null;
    readonly outbound: IGraphDatum<number, number> | null;
    readonly inner: IGraphDatum<number, number> | null;
  };
  hoveredSeries: string | null;
}

const COLORS = {
  inner: colors.primaryColor,
  inbound: colors.lightBlue,
  outbound: colors.darkGreen
};

/**
 * Map the data to the graph structure
 */
function mapData(yearValue: IYearValue): IGraphDatum<number, number> {
  return {
    x: yearValue.year,
    y: yearValue.value
  };
}

/**
 * There are new props on their way
 */
const useData = (
  data: IMunicipalityData | null
): {
  minYear: number | null;
  maxYear: number | null;
  domain: [number, number] | null;
  tickTotal: number | null;
  graphs: {
    inbound: IGraphDatum<number, number>[];
    outbound: IGraphDatum<number, number>[];
    inner: IGraphDatum<number, number>[];
  } | null;
  invisibleLineData: IGraphDatum<number, number>[] | null;
} => {
  const [invisibleLineData, setInvisibleLineData] = useState<
    IGraphDatum<number, number>[] | null
  >(null);
  const [maxYear, setMaxYear] = useState<IYearValue>({value: 0, year: 0});
  const [minYear, setMinYear] = useState<IYearValue>({value: 0, year: 0});
  const [domain, setDomain] = useState<[number, number] | null>(null);
  const [tickTotal, setTickTotal] = useState<number | null>(null);
  const [graphs, setGraphs] = useState<{
    inbound: IGraphDatum<number, number>[];
    outbound: IGraphDatum<number, number>[];
    inner: IGraphDatum<number, number>[];
  } | null>(null);

  useEffect((): void => {
    if (data) {
      const employedData: IEmployed = data.employed;
      setGraphs({
        inbound: employedData.inbound.map(mapData),
        outbound: employedData.outbound.map(mapData),
        inner: employedData.inner.map(mapData)
      });

      const allData: IYearValue[] = [
        ...employedData.inbound,
        ...employedData.outbound,
        ...employedData.inner
      ];
      const maxByValue: IYearValue = maxBy(allData, 'value') || allData[0];
      const roundTo: number = maxByValue.value > 10000 ? 10000 : 1000;
      const minDomain = 0;
      const maxDomain: number =
        Math.floor(maxByValue.value / roundTo + 1) * roundTo;

      setDomain([minDomain, maxDomain]);
      setTickTotal(Math.min((maxDomain - minDomain) / roundTo, 10));

      setMinYear(minBy(allData, 'year') || allData[0]);
      setMaxYear(maxBy(allData, 'year') || allData[allData.length - 1]);
    }
  }, [data]);

  useEffect((): void => {
    if (minYear && maxYear) {
      setInvisibleLineData(createInvisibleLineData(minYear.year, maxYear.year));
    }
  }, [minYear, maxYear]);

  return {
    minYear: minYear.year,
    maxYear: maxYear.year,
    domain,
    tickTotal,
    graphs,
    invisibleLineData
  };
};

/**
 * Remember the value that get’s hovered in the chart
 */
const handleNearestX = (
  hoveredDatum: IGraphDatum<number, number>,
  graphs: {
    inbound: IGraphDatum<number, number>[];
    outbound: IGraphDatum<number, number>[];
    inner: IGraphDatum<number, number>[];
  }
): {
  inbound: IGraphDatum<number, number> | null;
  outbound: IGraphDatum<number, number> | null;
  inner: IGraphDatum<number, number> | null;
} => {
  const year: number = hoveredDatum.x;

  return {
    inbound:
      graphs.inbound.find((graphDatum): boolean => graphDatum.x === year) ||
      null,
    outbound:
      graphs.outbound.find((graphDatum): boolean => graphDatum.x === year) ||
      null,
    inner:
      graphs.inner.find((graphDatum): boolean => graphDatum.x === year) || null
  };
};

/**
 * Render the hint
 */
const renderHint = (hoveredValues, hoveredSeries): JSX.Element | null => {
  if (!hoveredSeries || !hoveredValues) {
    return null;
  }

  const hoveredValue: IGraphDatum<number, number> | null =
    hoveredValues[hoveredSeries];

  if (!hoveredValue) {
    return null;
  }

  return (
    <Hint
      value={hoveredValue}
      align={{
        horizontal: Hint.ALIGN.RIGHT,
        vertical: Hint.ALIGN.TOP
      }}>
      <div className="visualization__hint">
        <span className="visualization__hint__value">
          {hoveredValue.y.toLocaleString('de')}
        </span>
        <span className="visualization__hint__year">{hoveredValue.x}</span>
      </div>
    </Hint>
  );
};

/**
 * Render the Component
 */
const Employed = ({data, size, print}: IProps): JSX.Element => {
  const [hoveredValue, setHoveredValue] = useState<{
    inbound: IGraphDatum<number, number> | null;
    outbound: IGraphDatum<number, number> | null;
    inner: IGraphDatum<number, number> | null;
  } | null>(null);
  const [hoveredSeries, setHoveredSeries] = useState<string | null>(null);

  const {
    minYear,
    maxYear,
    domain,
    tickTotal,
    graphs,
    invisibleLineData
  } = useData(data);

  if (
    !minYear ||
    !maxYear ||
    !domain ||
    !tickTotal ||
    !graphs ||
    !invisibleLineData
  ) {
    return <Loading />;
  }

  const marginXYPlot = print
  ? {left: 80, right: 30, top: 10, bottom: 40}
  : {left: 100, right: 20, top: 10, bottom: 40};

  return (
    <div>
      <p>
        Entwicklung der Zahl der Beschäftigten {minYear} bis {maxYear}
      </p>
      <p>differenziert nach Wohn- und Arbeitsort.</p>

      <XYPlot
        width={print ? 750 : size.width}
        height={266}
        margin={marginXYPlot}
        yDomain={domain}>
        <HorizontalGridLines tickTotal={tickTotal} />

        <LineSeries
          data={graphs.inbound}
          color={COLORS.inbound}
          onSeriesMouseOver={(): void => setHoveredSeries('inbound')}
          onSeriesMouseOut={(): void => setHoveredSeries(null)}
        />

        <LineSeries
          data={graphs.outbound}
          color={COLORS.outbound}
          onSeriesMouseOver={(): void => setHoveredSeries('outbound')}
          onSeriesMouseOut={(): void => setHoveredSeries(null)}
        />

        <LineSeries
          data={graphs.inner}
          color={COLORS.inner}
          onSeriesMouseOver={(): void => setHoveredSeries('inner')}
          onSeriesMouseOut={(): void => setHoveredSeries(null)}
        />

        <LineSeries
          data={invisibleLineData}
          color="transparent"
          onNearestX={(hoveredData): void =>
            setHoveredValue(handleNearestX(hoveredData, graphs))
          }
        />

        <XAxis
          tickTotal={maxYear - minYear}
          tickFormat={(tick): number => Number(tick)}
        />

        <YAxis
          tickTotal={Math.min(tickTotal, 8)}
          tickFormat={(tick): string => Number(tick).toLocaleString('de')}
        />

        {!print && (
          <span className="visualization--y-axis-label-rotated visualization--y-axis-label-distance-to-graph">
            Sozialversicherungspflichtig Beschäftigte
          </span>
        )}

        {renderHint(hoveredValue, hoveredSeries)}
      </XYPlot>

      {!print && (
        <Legend
          entries={[
            {
              color: COLORS.inner,
              description: 'Wohnsitz und Arbeitsstätte in der Gemeinde'
            },
            {
              color: COLORS.inbound,
              description: 'Einpendler'
            },
            {
              color: COLORS.outbound,
              description: 'Auspendler'
            }
          ]}
        />
      )}

      {!print && (
        <p className={clsx(styles.note, styles.hideOnMobile)}>
          Wenn Sie mit der Maus über das Diagramm fahren, werden Ihnen die
          Einzelwerte angezeigt.
        </p>
      )}
    </div>
  );
};

export default sizeme()(Employed);
