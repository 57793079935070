import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {AreaTypeIds} from '../../../constants/area-types';
import {Tab, Tabs} from '@material-ui/core';

import {areaTypeIdSelector} from '../../../selectors/area';

import workplaceDevelopmentSelector from '../../../selectors/workplace-development';
import workplaceStructureSelector from '../../../selectors/workplace-structure';

import LineChart from './line-chart';
import PieChart from './pie-chart';

import styles from '../chart-views.styl';

const title = 'Arbeitsplätze';
const sectionTitles = {
  development: 'Anzahl',
  structure: 'Branchenstruktur'
};

/**
 * The workplace development of a municipality
 */
const WorkplaceDevelopment = (): JSX.Element | null => {
  const areaTypeId = useSelector(areaTypeIdSelector);
  const developmentData = useSelector(workplaceDevelopmentSelector);
  const structureData = useSelector(workplaceStructureSelector);
  const [selectedTab, setSelectedTab] = useState(0);

  if (areaTypeId !== AreaTypeIds.Industrial) {
    return null;
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <div>
          <h1>{title}</h1>
        </div>
      </header>

      <div className={styles.tabContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(_, newValue): void => {
            setSelectedTab(newValue);
          }}>
          <Tab disableRipple label={sectionTitles.development} />
          <Tab disableRipple label={sectionTitles.structure} />
        </Tabs>
      </div>
      <div className={styles.contentContainer}>
        {selectedTab === 0 && <LineChart data={developmentData} />}
        {selectedTab === 1 && <PieChart data={structureData} />}
      </div>
    </section>
  );
};

export default WorkplaceDevelopment;
