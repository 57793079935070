import {IStore} from '../interfaces/store';
import {Industries} from '../constants/workplace-development'; // eslint-disable-line no-unused-vars, max-len

/**
 * The workplace development data
 */
export default function(state: IStore): Map<Industries, number> | null {
  const {area, municipality} = state;
  const areaSize = area.size || 1;

  if (!area.densityId || !municipality || !municipality.data) {
    return null;
  }

  const {workplaceDevelopment} = municipality.data;

  if (!workplaceDevelopment || !workplaceDevelopment[area.densityId]) {
    return null;
  }

  const data = new Map<Industries, number>(),
    rawData: {[key: number]: number} = workplaceDevelopment[area.densityId],
    industryIds: Industries[] = Object.keys(rawData).map(industry =>
      Number(industry)
    );

  industryIds.forEach(industry => {
    const value = rawData[industry] * areaSize;

    if (value === 0) {
      return;
    }

    data.set(industry, value);
  });

  return data;
}
