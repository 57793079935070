import {IStore} from '../../interfaces/store';
import {saveAs} from 'file-saver';
import {Dispatch} from 'redux';
import {DOWNLOAD} from '../../constants/actions';

/**
 * Save the settings
 */
export const downloadSettings = (): ((
  dispatch: Dispatch,
  getState: () => IStore
) => void) => {
  return (dispatch, getState): void => {
    dispatch({
      type: DOWNLOAD
    });
    const state: IStore = getState();
    const {municipality, area, map, areaConfig, traffic} = state;

    const file = new Blob(
      [JSON.stringify({municipality, area, map, areaConfig, traffic}, null, 2)],
      {type: 'text/plain;charset=utf-8'}
    );

    saveAs(file, `projekt-check-${area.name}.json`);
  };
};
