import React from 'react';

import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

import {NetworkElementGroupIds} from '../../../constants/network-elements';
import LegendColor from '../legend-color/legend-color';

import ByCostObject, {
  calculateGraphs
} from '../../chart-views/followup-costs/by-cost-object';

import colors from '../../../constants/colors';
import {IAreaConfig} from '../../../constants/area-config';
import {IFollowupCosts} from '../../../interfaces/followup-costs';
import {useSelector} from 'react-redux';
import {IStore} from '../../../interfaces/store';

type IProps = {
  areaConfig: IAreaConfig;
  data: Map<NetworkElementGroupIds, IFollowupCosts>;
};

const CostsByObject = ({areaConfig, data}: IProps): JSX.Element => {
  const followupCostsDataByObject = useSelector(
    (store: IStore) => store.followupCostsData.byCostsObject
  );

  const followupTableData = calculateGraphs(
    data,
    followupCostsDataByObject[areaConfig.areaTypeId]
  ).reduce((r, entry) => {
    entry.data.forEach(d => {
      if (!r[d.y]) {
        r[d.y] = [];
      }
      r[d.y].push({x: d.x, key: entry.key});
    });

    return r;
  }, {});

  return (
    <PrintPage title="Folgekosten im Bereich der technischen Infrastrukturen (Fortsetzung)">
      <PrintParagraph
        aside={<h3>Gesamtkosten, differenziert nach Kostenträger</h3>}
        main={
          <>
            <p>Schätzung der Aufteilung der Gesamtkosten (Neuerschließung)</p>
            <p>
              Die Annahmen zur Kostenaufteilung finden sich im separaten
              Dokument &quot;Methodik&quot;.
            </p>
            <ByCostObject
              data={data}
              followupCostsDataByObject={followupCostsDataByObject}
              areaTypeId={areaConfig.areaTypeId}
              print
            />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <LegendColor color={colors.primaryColor} />
                    Grundstücksbesitzer
                  </th>
                  <th>
                    <LegendColor color={colors.lightRed} />
                    Gemeinde
                  </th>
                  <th>
                    <LegendColor color={colors.lightGreen} />
                    Allgemeinheit der Tarifkunden
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(followupTableData).map(key => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>
                      {(
                        Math.round(followupTableData[key][0].x / 1000) * 1000
                      ).toLocaleString('de')}{' '}
                      €
                    </td>
                    <td>
                      {(
                        Math.round(followupTableData[key][1].x / 1000) * 1000
                      ).toLocaleString('de')}{' '}
                      €
                    </td>
                    <td>
                      {(
                        Math.round(followupTableData[key][2].x / 1000) * 1000
                      ).toLocaleString('de')}{' '}
                      €
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        }
      />
    </PrintPage>
  );
};

export default CostsByObject;
