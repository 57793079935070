import { IAction } from '../interfaces/action';
import { Ecologies } from '../constants/ecologies';
import { ViewIds } from '../constants/views'; // eslint-disable-line no-unused-vars, max-len
import { poiCategoryIds as locationCompetitionPoiCategoryIds } from '../constants/location-competition';
import { SELECT_VIEW } from '../constants/actions';

import fetchEcologyLayer from './ecology/fetch-ecology-layer';
import fetchFollowupCostsData from './fetch-followup-costs-data';
import fetchPopulationStructure from './fetch-population-structure';
import fetchIsochrones from './fetch-isochrones';
import fetchPois from './fetch-pois';
import openLegend from '../actions/open-legend';
import closeLegend from '../actions/close-legend';
import { Dispatch } from 'react';

/**
 * Select a view
 */
function selectView(viewId: ViewIds): IAction {
  return {
    type: SELECT_VIEW,
    viewId
  };
}

const toggleLegend = (dispatch: Dispatch<IAction>, isDesktop: boolean): void => {
  if (isDesktop) {
    dispatch(openLegend());
  } else {
    dispatch(closeLegend());
  }
};

/**
 * Select a view
 */
export default function select(view: ViewIds, isDesktop = true): (dispatch) => void {
  return (dispatch): void => {
    dispatch(selectView(view));

    if (Ecologies.has(view)) {
      dispatch(fetchEcologyLayer(view));
      toggleLegend(dispatch, isDesktop);
    }

    if (view === ViewIds.PopulationDevelopment) {
      dispatch(fetchPopulationStructure());
    }

    if (view === ViewIds.FollowupCosts) {
      dispatch(fetchFollowupCostsData());
    }

    if (view === ViewIds.TrafficReachability) {
      dispatch(fetchIsochrones());
      dispatch(fetchPois());
      toggleLegend(dispatch, isDesktop);
    }

    if (view === ViewIds.LocationCompetition) {
      dispatch(fetchPois(locationCompetitionPoiCategoryIds));
      toggleLegend(dispatch, isDesktop);
    }
  };
}
