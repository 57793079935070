export const zoom = 7;

export const center: google.maps.LatLngLiteral = {
  lat: 51.5210461,
  lng: 10.7837579
};

export const bounds: google.maps.LatLngBoundsLiteral = {
  north: 54,
  east: 15,
  south: 48,
  west: 6
};

export const styles: google.maps.MapTypeStyle[] = [
  {featureType: 'poi.attraction', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.business', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.medical', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.place_of_worship', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.school', stylers: [{visibility: 'off'}]},
  {featureType: 'poi.sports_complex', stylers: [{visibility: 'off'}]}
];
