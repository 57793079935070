import {useEffect, useState, useRef} from 'react';
// @ts-ignore has no exported member 'InfoBox'
import {InfoBox} from 'google-maps-infobox';
import {poiCategories, IPoiCategory} from '../../constants/pois';

interface IProps {
  pois: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
  mapCanvas: google.maps.Map | null;
}

type IPoiMarker = {
  marker: google.maps.Marker | null;
  poi: GeoJSON.Feature<GeoJSON.Point>;
  poiCategory?: IPoiCategory;
};

const createPoi = (
  poi: GeoJSON.Feature<GeoJSON.Point>,
  mapCanvas: google.maps.Map
): IPoiMarker => {
  const poiCategory = poiCategories.find(
    // eslint-disable-next-line camelcase
    category => category.key === poi?.properties?.projektcheck_category
  );

  const iconName = poiCategory?.iconName;
  const size = poiCategory?.iconSize === 'small' ? 12 : 32;
  const anchor = size / 2;
  const isMultiPoint = Array.isArray(poi.geometry.coordinates[0]);

  const coordinates = isMultiPoint
    ? poi.geometry.coordinates[0]
    : poi.geometry.coordinates;

  const marker = new google.maps.Marker({
    position: {
      lat: coordinates[1],
      lng: coordinates[0]
    },
    map: mapCanvas,
    icon: {
      url: `./images/poi-${iconName}.png`,
      size: new google.maps.Size(size, size),
      scaledSize: new google.maps.Size(size, size),
      anchor: new google.maps.Point(anchor, anchor)
    }
  });

  return {marker, poi, poiCategory};
};

/**
 * Some POIs
 */
export const Pois = ({pois, mapCanvas}: IProps): null => {
  const [markers, setMarkers] = useState<IPoiMarker[]>([]);
  const infowindow = useRef<InfoBox>(null);

  /**
   * Open the infowindow for the passed marker and map
   */
  const openInfowindow = (options: {
    marker: google.maps.Marker;
    poi: GeoJSON.Feature<GeoJSON.Point>;
    poiCategory?: IPoiCategory;
  }): void => {
    const {marker, poi, poiCategory} = options;
    if (!poi.properties) {
      return;
    }

    if (!infowindow.current) {
      infowindow.current = new InfoBox({
        alignBottom: true,
        boxStyle: {
          overflow: 'visible'
        },
        closeBoxURL: '',
        content: '',
        disableAutoPan: true,
        enableEventPropagation: false,
        maxWidth: 275,
        pixelOffset: new google.maps.Size(0, -15)
      });
    }

    infowindow.current.setContent(`
    <div class='poi'>
      <div class='poi__name'>${poi.properties.name || ''}</div>
      <div class='poi__type'>${poiCategory?.name}</div>
    </div>
  `);
    infowindow.current.open(mapCanvas, marker);
  };

  const closeInfowindow = (): void => {
    if (infowindow.current) {
      infowindow.current.close();
    }
  };

  useEffect(() => {
    if (pois && mapCanvas && markers.length === 0) {
      const poiMarkers = pois.features
        .map(poi => createPoi(poi, mapCanvas))
        .filter(poi => poi);

      poiMarkers.forEach(({marker, poi, poiCategory}) => {
        if (marker) {
          marker.addListener('mouseout', () => closeInfowindow());
          marker.addListener('mouseover', () =>
            openInfowindow({marker, poi, poiCategory})
          );
        }
      });

      setMarkers(poiMarkers);
    }

    return (): void => {
      markers.forEach(({marker}) => {
        if (marker) {
          google.maps.event.clearInstanceListeners(marker);
          marker.setMap(null);
          marker = null;
        }
      });
    };
  }, [pois, mapCanvas, markers]);

  return null;
};

export default Pois;
