import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch, Route, Switch, useHistory} from 'react-router-dom';
import {showLegendSelector} from '../../selectors/show-legend';

import useMediaQuery from '@material-ui/core/useMediaQuery';
// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';

import HeaderContainer from '../header/header-container';
import ProfiHint from './profi-hint/profi-hint';
import SelectedItemButton from './selected-item-button/selected-item-button';
import MenuActions from './menu-actions/menu-actions';
import Map from '../map/map';
import PrintDialog from './print-dialog/print-dialog';
import DownloadDialog from './download-dialog/download-dialog';

import {menuItems} from '../../constants/menu-items';
import MenuItems from './menu-items/menu-items';
import styles from './analyze-menu.styl';

interface IProps {
  onSidebarTextShown: () => void;
}

/**
 * The analyze menu on the left
 */
//eslint-disable-next-line complexity
const AnalyzeMenu = ({onSidebarTextShown}: IProps): JSX.Element | null => {
  const [showSidebarMenu, setShowSidebarMenu] = useState(true);
  const [showSidebarText, setShowSidebarText] = useState(false);
  const [itemSelected, setItemSelected] = useState('');
  const showLegend = useSelector(showLegendSelector);
  const isDesktop = useMediaQuery(mediaQueries.big);
  const fullscreenOverlayOpen = Boolean(
    useRouteMatch(['/views/:any/charts', '/views/:any/info'])
  );
  const history = useHistory();
  return (
    <>
      {(showSidebarMenu || showSidebarText || isDesktop) && (
        <div className={styles.container}>
          {!showSidebarText && (
            <div>
              <HeaderContainer />
              <div>
                <MenuItems
                  items={menuItems}
                  menuItemSelected={(itemName): void => {
                    setItemSelected(itemName);
                    setShowSidebarMenu(false);
                  }}
                />
              </div>
              <div className={styles.menuSeparator}></div>
              <div className={styles.containerMenuActions}>
                <MenuActions />
              </div>
            </div>
          )}
          <Switch>
            <Route exact path={'/views/export/print'}>
              <PrintDialog
                setShowSidebarText={(text): void => {
                  onSidebarTextShown();
                  setShowSidebarText(text);
                }}
              />
            </Route>
            <Route exact path={'/views/export/download'}>
              <DownloadDialog
                setShowSidebarText={(text): void => {
                  onSidebarTextShown();
                  setShowSidebarText(text);
                }}
              />
            </Route>
            <Route path={'/views'}>
              <ProfiHint
                setShowSidebarText={setShowSidebarText}
                isDesktop={isDesktop}
              />
            </Route>
          </Switch>
        </div>
      )}
      {!isDesktop && (
        <Map
          hide={showSidebarMenu || showSidebarText || fullscreenOverlayOpen}
        />
      )}

      {itemSelected && !showSidebarText && !showLegend && (
        <SelectedItemButton
          onClick={(): void => {
            setShowSidebarMenu(!showSidebarMenu);
            if (!showSidebarMenu) {
              history.push('/views');
            }
          }}
          onInfoClick={(): void => {
            setShowSidebarMenu(false);
          }}
          title={itemSelected}
          isOpened={!showSidebarMenu}
        />
      )}
    </>
  );
};

export default AnalyzeMenu;
