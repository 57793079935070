import {useEffect, useRef} from 'react';

// @ts-ignore has no exported member 'InfoBox'
import {InfoBox} from 'google-maps-infobox';

interface IProps {
  data: {
    [_: string]: string[];
  };
  mapCanvas: google.maps.Map;
  position: google.maps.LatLngLiteral;
}

const EcologyOverlayInfowindow = ({
  data,
  mapCanvas,
  position
}: IProps): null => {
  const infowindow = useRef<google.maps.InfoWindow>(
    new InfoBox({
      alignBottom: true,
      boxStyle: {
        overflow: 'visible'
      },
      closeBoxURL: '',
      content: '',
      disableAutoPan: true,
      enableEventPropagation: false,
      maxWidth: 275,
      pixelOffset: new google.maps.Size(0, -15)
    })
  );

  useEffect((): (() => any) => {
    if (data) {
      const content = Object.keys(data)
        .map((key): string => {
          const header = `<header class="poi__name">${key}</header>`;

          const names = data[key]
            .map(
              (name): string =>
                `<div class="poi__type">
            ${name}
          </div>`
            )
            .join('<br>');

          return header + names;
        })
        .join('<br>');

      infowindow.current.setContent(`<div class='poi'>${content}</div>`);
      infowindow.current.open(mapCanvas);
      infowindow.current.setPosition(new google.maps.LatLng(position));
    }
    return (): void => infowindow.current.close();
  }, [data]);

  return null;
};

export default EcologyOverlayInfowindow;
