import React from 'react';
import {HashRouter, Switch, Route} from 'react-router-dom';

import AppInteractive from './app-interactive';
import AppPrint from './print/app/app';

import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';

/**
 * The main app container
 */
const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Switch>
          <Route exact path="/print" component={AppPrint} />
          <Route path="/" component={AppInteractive} />
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
