import {IAction} from '../../interfaces/action';

import {
  ADD_TRAFFIC_ROUTE,
  GET_TRAFFIC_ROUTE,
  GET_TRAFFIC_ROUTE_ERROR
} from '../../constants/actions';
import getRouteApi from '../../apis/get-route';

/**
 * Get a traffic route to the destination
 */
export function getTrafficRoute(): IAction {
  return {
    type: GET_TRAFFIC_ROUTE
  };
}

/**
 * Add a route with the path and destination
 */
export function addTrafficRoute(
  destination: google.maps.LatLngLiteral,
  path: google.maps.LatLngLiteral[]
): IAction {
  return {
    type: ADD_TRAFFIC_ROUTE,
    route: {
      destination,
      path
    }
  };
}

/**
 * Error while fetching the municipalities
 */
export function addTrafficRouteError(error: Error): IAction {
  return {
    type: GET_TRAFFIC_ROUTE_ERROR,
    error
  };
}

/**
 * Add the route from origin to destination
 */
export default function(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral,
  getRouteRemotely: (
    origin: google.maps.LatLngLiteral,
    destination: google.maps.LatLngLiteral
  ) => Promise<google.maps.LatLngLiteral[]> = getRouteApi
) {
  return dispatch => {
    dispatch(getTrafficRoute());

    return getRouteRemotely(origin, destination)
      .then(routePath => dispatch(addTrafficRoute(destination, routePath)))
      .catch(error => dispatch(addTrafficRouteError(error)));
  };
}
