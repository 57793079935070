import {IStore} from '../../interfaces/store';
import {Dispatch} from 'redux';
import fileDialog from 'file-dialog';

import {IMPORT_SETTINGS, IMPORT_SETTINGS_ERROR} from '../../constants/actions';
import {IAction} from '../../interfaces/action';
import setAreaConfig from '../area/set-area-config';
import {areaConfigs} from '../../constants/area-config';
import getShapeBounds from '../../libs/get-shape-bounds';

export const importSettingsActionCreator = (settings): IAction => ({
  type: IMPORT_SETTINGS,
  settings
});

const importSettingsErrorActionCreator = (error): IAction => ({
  type: IMPORT_SETTINGS_ERROR,
  error
});

export const importSettings = (): ((
  dispatch: Dispatch,
  getState: () => IStore
) => void) => {
  return (dispatch): void => {
    fileDialog().then((files): void => {
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event) {
          try {
            // @ts-ignore Property 'result' does not exist on type 'EventTarget'.
            const settings: IStore = JSON.parse(event.target.result);
            const shape = settings.area.shape;
            if (shape) {
              const mapBounds = getShapeBounds(shape, 2);
              dispatch(
                importSettingsActionCreator({
                  ...settings,
                  map: {...settings.map, bounds: mapBounds}
                })
              );
            } else {
              dispatch(importSettingsActionCreator(settings));
            }

            if (!settings.areaConfig && settings.area.densityId) {
              const areaConfig = areaConfigs[settings.area.densityId];
              dispatch(setAreaConfig(areaConfig));
            }
          } catch (error) {
            dispatch(importSettingsErrorActionCreator(error));
          }
        }
      };
      reader.readAsText(files[0]);
    });
  };
};
