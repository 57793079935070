import React, { useEffect, useRef, useState } from 'react';

import { styles as mapStyles } from '../../config/map';

// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';
import { useMediaQuery } from '@material-ui/core';

import styles from './map.styl';

interface IProps {
  bounds: google.maps.LatLngBoundsLiteral;
  center: google.maps.LatLngLiteral;
  zoom: number;
  printVersion?: boolean;
  onInit: (canvas: google.maps.Map) => void;
  onCenterChange?: (center: google.maps.LatLngLiteral) => void;
  onZoomChange?: (zoom: number) => void;
  hide?: boolean;
}

const Canvas = ({
  bounds,
  center,
  zoom,
  printVersion,
  onInit,
  onCenterChange,
  onZoomChange,
  hide
}: IProps): JSX.Element => {
  /**
   * The container element to render the map canvas into
   */
  const canvas = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<google.maps.Map | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number | null>(null);
  const [
    currentBounds,
    setCurrentBounds
  ] = useState<google.maps.LatLngBoundsLiteral | null>(null);
  const isDesktop = useMediaQuery(mediaQueries.big);

  const mapOptions: google.maps.MapOptions = {
    backgroundColor: '#fff',
    center,
    disableDefaultUI: printVersion,
    disableDoubleClickZoom: printVersion,
    draggable: !printVersion,
    scrollwheel: !printVersion,
    panControl: false,
    scaleControl: false,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    fullscreenControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    styles: mapStyles,
    zoom
  };

  /**
   * Initialize map and listeners.
   */
  useEffect(() => {
    // @ts-ignore The canvas is available after first render
    // before this effect is run
    const map = new google.maps.Map(canvas.current, mapOptions);

    google.maps.event.addListener(map, 'center_changed', () => {
      const latLng = map.getCenter();

      if (onCenterChange) {
        onCenterChange(latLng.toJSON());
      }
    });
    google.maps.event.addListener(map, 'zoom_changed', () => {
      if (onZoomChange) {
        onZoomChange(map.getZoom());
      }
      if (map.getZoom() !== zoom) {
        setCurrentZoom(map.getZoom());
      }
    });

    google.maps.event.addListener(map, 'tilesloaded', () => {
      onInit(map);
      mapInstance.current = map;
    });
    return (): void => {
      google.maps.event.clearListeners(map, 'center_changed');
      google.maps.event.clearListeners(map, 'zoom_changed');
      google.maps.event.clearListeners(map, 'tilesloaded');
    };
  }, []);

  useEffect(() => {
    if (mapInstance.current && isDesktop) {
      // On desktop, set bounds immediately
      setCurrentBounds(bounds);
      mapInstance.current.fitBounds(bounds);
    }
  }, [bounds, mapInstance.current]);

  useEffect(() => {
    if (mapInstance.current && !isDesktop) {
      // If zoom has changed from default, apply it
      if (currentZoom && bounds === currentBounds) {
        mapInstance.current.setZoom(currentZoom);
      } else if (!hide) {
        setCurrentBounds(bounds);
        mapInstance.current.fitBounds(bounds);
      }
    }
  }, [hide, bounds, mapInstance.current]);

  return <div className={styles.canvas} ref={canvas} />;
};

export default Canvas;
