/**
 * Actions that can be used for the redux store and actions
 */

export const UPDATE_MAP_BOUNDS = 'UPDATE_MAP_BOUNDS';
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const UPDATE_MAP_ZOOM = 'UPDATE_MAP_ZOOM';

export const IMPORT_SETTINGS = 'IMPORT_SETTINGS';
export const IMPORT_SETTINGS_ERROR = 'IMPORT_SETTINGS_ERROR';
export const DOWNLOAD = 'DOWNLOAD';
export const START_ANALYSE = 'START_ANALYSE';

export const SELECT_VIEW = 'SELECT_VIEW';
export const OPEN_INFORMATION = 'OPEN_INFORMATION';
export const CLOSE_INFORMATION = 'CLOSE_INFORMATION';

export const SELECT_MUNICIPALITY = 'SELECT_MUNICIPALITY';
export const SELECT_AREA_NAME = 'SELECT_AREA_NAME';
export const SELECT_AREA_SIZE = 'SELECT_AREA_SIZE';
export const SELECT_AREA_TYPE_ID = 'SELECT_AREA_TYPE_ID';
export const SELECT_AREA_INFLUX = 'SELECT_AREA_INFLUX';
export const SELECT_AREA_DENSITY_ID = 'SELECT_AREA_DENSITY_ID';
export const SELECT_AREA_SHAPE = 'SELECT_AREA_SHAPE';
export const DELETE_AREA_SHAPE = 'DELETE_AREA_SHAPE';
export const SET_AREA_CONFIG = 'SET_AREA_CONFIG';
export const UPDATE_AREA_CONFIG = 'UPDATE_AREA_CONFIG';

export const ADD_TRAFFIC_ROUTE = 'ADD_TRAFFIC_ROUTE';
export const GET_TRAFFIC_ROUTE = 'GET_TRAFFIC_ROUTE';
export const GET_TRAFFIC_ROUTE_ERROR = 'GET_TRAFFIC_ROUTE_ERROR';
export const CLEAR_TRAFFIC_ROUTES = 'CLEAR_TRAFFIC_ROUTES';

export const FETCH_MUNICIPALITIES = 'FETCH_MUNICIPALITIES';
export const FETCH_MUNICIPALITIES_SUCCESS = 'FETCH_MUNICIPALITIES_SUCCESS';
export const FETCH_MUNICIPALITIES_ERROR = 'FETCH_MUNICIPALITIES_ERROR';

export const FETCH_MUNICIPALITY_DATA = 'FETCH_MUNICIPALITY_DATA';
export const FETCH_MUNICIPALITY_DATA_SUCCESS =
  'FETCH_MUNICIPALITY_DATA_SUCCESS';
export const FETCH_MUNICIPALITY_DATA_ERROR = 'FETCH_MUNICIPALITY_DATA_ERROR';

export const FETCH_ECOLOGY_LAYER = 'FETCH_ECOLOGY_LAYER';
export const FETCH_ECOLOGY_LAYER_SUCCESS = 'FETCH_ECOLOGY_LAYER_SUCCESS';
export const FETCH_ECOLOGY_LAYER_ERROR = 'FETCH_ECOLOGY_LAYER_ERROR';

export const FETCH_ECOLOGY_LAYER_INFORMATION =
  'FETCH_ECOLOGY_LAYER_INFORMATION';
export const FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS =
  'FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS';
export const FETCH_ECOLOGY_LAYER_INFORMATION_ERROR =
  'FETCH_ECOLOGY_LAYER_INFORMATION_ERROR';
export const REMOVE_ECOLOGY_LAYER_INFORMATION =
  'REMOVE_ECOLOGY_LAYER_INFORMATION';

export const FETCH_POPULATION_STRUCTURE = 'FETCH_POPULATION_STRUCTURE';
export const FETCH_POPULATION_STRUCTURE_SUCCESS =
  'FETCH_POPULATION_STRUCTURE_SUCCESS';
export const FETCH_POPULATION_STRUCTURE_ERROR =
  'FETCH_POPULATION_STRUCTURE_ERROR';

export const FETCH_FOLLOWUP_COSTS_DATA = 'FETCH_FOLLOWUP_COSTS_DATA';
export const FETCH_FOLLOWUP_COSTS_DATA_SUCCESS =
  'FETCH_FOLLOWUP_COSTS_DATA_SUCCESS';
export const FETCH_FOLLOWUP_COSTS_DATA_ERROR =
  'FETCH_FOLLOWUP_COSTS_DATA_ERROR';

export const FETCH_ISOCHRONES = 'FETCH_ISOCHRONES';
export const FETCH_ISOCHRONES_SUCCESS = 'FETCH_ISOCHRONES_SUCCESS';
export const FETCH_ISOCHRONES_ERROR = 'FETCH_ISOCHRONES_ERROR';

export const FETCH_POIS = 'FETCH_POIS';
export const FETCH_POIS_SUCCESS = 'FETCH_POIS_SUCCESS';
export const FETCH_POIS_ERROR = 'FETCH_POIS_ERROR';

export const OPEN_LEGEND = 'OPEN_LEGEND';
export const CLOSE_LEGEND = 'CLOSE_LEGEND';
