import {debounce} from 'lodash-es';

interface IParams {
  service?: string;
  version?: string;
  request?: string;
  srsName?: string;
  bbox?: string;
  typeNames?: string;
  outputFormat?: string;
}
interface IData {
  url: string;
  version?: string;
  layers: {id: string}[];
}

/**
 * A WMS map overlay type
 */
export default class WfsMapType {
  name: string;
  url: string | null;
  bboxEPSGtype: string;
  mapInstance: google.maps.Map;

  /**
   * Params representing key/value pairs included in the GetMap query.
   */
  params: IParams = {
    service: 'WFS',
    version: '2.0.0',
    request: 'GetFeature',
    srsName: 'EPSG:4326',
    bbox: '',
    typeNames: 'dlm250:objart_51005_l',
    outputFormat: 'json'
  };

  boundsChangedListener: google.maps.MapsEventListener;

  /**
   * Construct the overlay
   */
  constructor(name: string, data: IData) {
    this.name = name;
    this.url = data.url;
    this.bboxEPSGtype = 'urn:ogc:def:crs:EPSG:4326';

    this.params.typeNames = data.layers
      .map((layer): string => layer.id)
      .join(',');
  }

  /*
   * Prototype getTile method.
   */
  async getFeatures(): Promise<GeoJSON.FeatureCollection | void> {
    const params: string[] = [];
    const bounds = this.mapInstance.getBounds();

    if (bounds) {
      this.params.bbox = `${bounds.toUrlValue()},${this.bboxEPSGtype}`;
    }

    Object.keys(this.params).forEach((key): void => {
      params.push(`${key}=${this.params[key]}`);
    });

    const url = `${this.url}?${params.join('&')}`;

    try {
      const res = await fetch(url);
      return await res.json();
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Fetch the features from the WFS and add them to the data layer of the map
   */
  async addFeatures(): Promise<void> {
    const features = await this.getFeatures();
    if (features && this.url) {
      this.removeFeatures();
      this.mapInstance.data.setStyle({
        strokeColor: '#000',
        strokeWeight: 2
      });
      this.mapInstance.data.addGeoJson(features);
    }
  }

  /**
   * Remove features from the data layer of the map instance
   */
  removeFeatures(): void {
    this.mapInstance.data.forEach((feature): void =>
      this.mapInstance.data.remove(feature)
    );
  }

  /*
   * Add this data overlay to a map
   */
  addToMap(map: google.maps.Map): void {
    this.mapInstance = map;
    this.addFeatures();
    this.addChangeListener();
  }

  addChangeListener(): void {
    this.boundsChangedListener = this.mapInstance.addListener(
      'bounds_changed',
      debounce((): Promise<void> => this.addFeatures(), 500)
    );
  }

  removeChangeListener(): void {
    this.boundsChangedListener.remove();
  }

  /*
   * Remove this data overlay from a map.
   */
  removeFromMap(): void {
    this.url = null;
    this.removeFeatures();
    this.removeChangeListener();
  }
}
