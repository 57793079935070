const TILE_SIZE = 256;
const EARTH_RADIUS_IN_METERS = 6378137;
const CIRCUMFERENCE: number = 2 * Math.PI * EARTH_RADIUS_IN_METERS;

const tileSize: google.maps.Size = new google.maps.Size(TILE_SIZE, TILE_SIZE);

interface IParams {
  service?: string;
  version?: string;
  request?: string;
  transparent?: boolean;
  format?: string;
  width?: number;
  height?: number;
  srs?: string;
  crs?: string;
  layers?: string;
  styles?: string;
}
interface IData {
  url: string;
  version?: string;
  layers: {id: string}[];
}
interface IOptions {
  cache?: boolean;
  opacity?: number;
}

/*
 * Translate the xy & resolution to spherical mercator (EPSG:3857, EPSG:900913).
 */
function getMercatorCoord(
  x: number,
  y: number,
  resolution: number
): google.maps.LatLngLiteral {
  return {
    lng: x * TILE_SIZE * resolution - CIRCUMFERENCE / 2,
    lat: y * TILE_SIZE * resolution - CIRCUMFERENCE / 2
  };
}

/*
 * Return the tile bounds for the given x, y, z values.
 */
function getBounds(
  x: number,
  y: number,
  z: number
): google.maps.LatLngBoundsLiteral {
  const cleanY = Math.pow(2, z) - y - 1;
  // meters per pixel
  const resolution: number = CIRCUMFERENCE / TILE_SIZE / Math.pow(2, z);
  const swPoint: google.maps.LatLngLiteral = getMercatorCoord(
    x,
    cleanY,
    resolution
  );
  const nePoint: google.maps.LatLngLiteral = getMercatorCoord(
    x + 1,
    cleanY + 1,
    resolution
  );

  return {
    west: swPoint.lng,
    south: swPoint.lat,
    east: nePoint.lng,
    north: nePoint.lat
  };
}

/**
 * A WMS map overlay type
 */
export default class WmsMapType {
  /**
   * The name of the overlay
   */
  name: string;

  /**
   * URL to the WMS
   */
  url: string;

  /**
   * The tiles of the overlay
   */
  tiles: HTMLDivElement[] = [];

  /**
   * The tile size.
   * This is required by the API.
   */
  tileSize: google.maps.Size = tileSize;

  /**
   * Params representing key/value pairs included in the GetMap query.
   */
  params: IParams = {
    service: 'WMS',
    version: '1.3.0',
    request: 'GetMap',
    transparent: true,
    format: 'image/png',
    width: tileSize.width,
    height: tileSize.height,
    crs: 'EPSG:3857',
    layers: '',
    styles: ''
  };

  /**
   * Some options for the overlay rendering
   */
  options = {
    opacity: 0.5,
    cache: false
  };

  /**
   * Construct the overlay
   */
  constructor(name: string, data: IData) {
    this.name = name;
    this.url = data.url;

    this.params.layers = data.layers.map((layer): string => layer.id).join(',');
  }

  /*
   * Prototype getTile method.
   */
  // eslint-disable-next-line max-statements
  getTile(
    coord: google.maps.Point,
    zoom: number,
    ownerDocument: HTMLDocument
  ): HTMLDivElement {
    const div: HTMLDivElement = ownerDocument.createElement('div');

    if (!this.params.layers || !this.params.layers.length) {
      console.error('[WmsMapType] Required param ‘layers’ is empty');
      return div;
    }

    const params: string[] = [];
    const bounds = getBounds(coord.x, coord.y, zoom);

    Object.keys(this.params).forEach(
      (key): void => {
        params.push(`${key}=${this.params[key]}`);
      }
    );

    params.push(
      `bbox=${bounds.west},${bounds.south},${bounds.east},${bounds.north}`
    );

    if (!this.options.cache) {
      const date: Date = new Date();
      params.push(`cache=${date.getTime()}`);
    }

    const url = `${this.url}${params.join('&')}`;

    div.innerHTML = `<img src="${url}"/>`;
    div.style.width = `${tileSize.width}px`;
    div.style.height = `${tileSize.height}px`;
    div.style.opacity = String(this.options.opacity);

    this.tiles.push(div);

    return div;
  }

  /*
   * Add this MapType to a map at the given index, or on top of other layers
   * if index is omitted.
   */
  addToMap(map: google.maps.Map, index?: number): void {
    if (index || index === 0) {
      map.overlayMapTypes.insertAt(
        Math.min(index, map.overlayMapTypes.getLength()),
        this
      );
    } else {
      map.overlayMapTypes.push(this);
    }
  }

  /**
   * When a tile is released
   */
  releaseTile(): void {
    // Needed to match the google.maps.MapType interface.
  }

  /*
   * Remove this MapType from a map.
   */
  removeFromMap(map: google.maps.Map): void {
    const overlayTypes = map.overlayMapTypes;

    for (let i = 0; i < overlayTypes.getLength(); i++) {
      const element = overlayTypes.getAt(i);

      if (element && element === this) {
        overlayTypes.removeAt(i);
        break;
      }
    }

    this.tiles = [];
  }

  /*
   * Change opacity on demand.
   */
  setOpacity(opacity: number): void {
    this.options.opacity = opacity;

    this.tiles.forEach(
      (tile): void => {
        tile.style.opacity = String(opacity);
      }
    );
  }
}
