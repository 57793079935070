import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import deleteAreaShape from '../../../actions/area/delete-area-shape';
import DeleteIcon from '@material-ui/icons/Delete';
import {Button, Box} from '@material-ui/core';
import {areaSelector} from '../../../selectors/area';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: '20px'
  }
});

const AreaShapeDeleteButton = (): JSX.Element | null => {
  const hasArea = Boolean(useSelector(areaSelector)?.shape);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleDeleteButton = (): void => {
    dispatch(deleteAreaShape());
    history.push('/config/area');
  };

  return (
    <Button
      className={classes.root}
      disableElevation
      disableRipple
      disabled={!hasArea}
      variant="contained"
      color="primary"
      onClick={(): void => {
        handleDeleteButton();
      }}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <div>Ausweisung zurücksetzen</div>
        <DeleteIcon />
      </Box>
    </Button>
  );
};

export default AreaShapeDeleteButton;
