import {IAction} from '../interfaces/action';
import {IStore} from '../interfaces/store';
import {AreaTypeIds} from '../constants/area-types'; // eslint-disable-line no-unused-vars, max-len
import {PoiCategoryIds} from '../constants/pois'; // eslint-disable-line no-unused-vars, max-len

import {
  FETCH_POIS,
  FETCH_POIS_SUCCESS,
  FETCH_POIS_ERROR
} from '../constants/actions';
import fetchApi from '../apis/fetch-pois';

/**
 * Fetching the POIs
 */
export function fetchPois(): IAction {
  return {
    type: FETCH_POIS
  };
}

/**
 * Success while fetching the POIs
 */
export function fetchPoisSuccess(
  pois: GeoJSON.FeatureCollection<GeoJSON.Point>
): IAction {
  return {
    type: FETCH_POIS_SUCCESS,
    pois
  };
}

/**
 * Error while fetching the POIs
 */
export function fetchPoisError(error: Error): IAction {
  return {
    type: FETCH_POIS_ERROR,
    error
  };
}

/**
 * Fetch some POIs
 */
export default function(
  poiCategoryIds?: PoiCategoryIds[],
  fetchRemotely: (
    center: google.maps.LatLngLiteral,
    areaTypeId: AreaTypeIds,
    poiCategoryIds?: PoiCategoryIds[]
  ) => Promise<GeoJSON.FeatureCollection<GeoJSON.Point>> = fetchApi
) {
  return (dispatch, getState: () => IStore) => {
    const state: IStore = getState();

    dispatch(fetchPois());

    const {center, typeId} = state.area;

    if (!center || !typeId) {
      return null;
    }

    return fetchRemotely(center, typeId, poiCategoryIds)
      .then(pois => dispatch(fetchPoisSuccess(pois)))
      .catch(error => dispatch(fetchPoisError(error)));
  };
}
