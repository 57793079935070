import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';
import {
  municipalitiesSelector,
  municipalitySelector
} from '../../../selectors/configuration-municipality';
import selectMunicipality from '../../../actions/municipalities/select-municipality';
import resetMunicipality from '../../../actions/municipalities/reset-municipality';
import MunicipalityInput from './municipality-input/municipality-input';
import Icon from '../../shared/icon';
import styles from './configuration-municipality.styl';
import {Button, Box} from '@material-ui/core';

const ConfigurationMunicipality = (): JSX.Element => {
  const municipalities = useSelector(municipalitiesSelector);
  const municipality = useSelector(municipalitySelector);
  const dispatch = useDispatch();
  const recentDevelopmentIsOpen = useRouteMatch('/config/municipality/recent');

  return (
    <div className={styles.container}>
      <MunicipalityInput
        municipality={municipality}
        municipalities={municipalities}
        onSelectMunicipality={(selectedMunicipality): void => {
          dispatch(selectMunicipality(selectedMunicipality));
        }}
        onResetMunicipality={(): void => {
          dispatch(resetMunicipality);
        }}
      />
      {municipality && (
        <Button
          disableElevation
          disableRipple
          className={styles.recentDevelopmentButton}
          variant="contained"
          color="primary"
          component={Link}
          to={
            recentDevelopmentIsOpen
              ? '/config/municipality'
              : `/config/municipality/recent`
          }>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <div>Bisherige Entwicklung in {municipality.name}</div>
            <Icon type="info" />
          </Box>
        </Button>
      )}
    </div>
  );
};

export default ConfigurationMunicipality;
