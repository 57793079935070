import {IStore} from '../../interfaces/store';
import {IEcologyLayer} from '../../interfaces/ecology';
import {ViewIds} from '../../constants/views';
import React from 'react';
import {connect} from 'react-redux';

import getShapeBounds from '../../libs/get-shape-bounds';

import Canvas from './canvas';
import AreaShape from './area-shape';
import EcologyOverlay from './ecology-overlay';

interface IStoreProps {
  areaCenter: google.maps.LatLngLiteral | null;
  areaShape: google.maps.LatLngLiteral[] | null;
  ecologyLayers: Map<ViewIds, IEcologyLayer>;
}
interface IParentProps {
  view: ViewIds;
}
type IProps = IStoreProps & IParentProps;
interface IState {
  mapCanvas: google.maps.Map | null;
}

/**
 * The map container
 */
class MapEcology extends React.Component<IProps, IState> {
  /**
   * The map state
   */
  state: IState = {
    mapCanvas: null
  };

  /**
   * Handle the init of the Google Maps Canvas
   */
  handleMapCanvasInit(mapCanvas: google.maps.Map): void {
    this.setState({mapCanvas});
  }

  /**
   * Render the Component
   */
  render(): JSX.Element | null {
    const {areaShape, areaCenter, view} = this.props;
    const {mapCanvas} = this.state;

    if (!areaShape || !areaCenter) {
      return null;
    }

    const bounds = getShapeBounds(areaShape, 2);

    return (
      <div className="map">
        <Canvas
          bounds={bounds}
          center={areaCenter}
          zoom={14}
          printVersion={true}
          onInit={this.handleMapCanvasInit.bind(this)}
        />

        <AreaShape mapCanvas={mapCanvas} shape={this.props.areaShape} />

        <EcologyOverlay
          mapCanvas={mapCanvas}
          view={view}
          ecologyLayers={this.props.ecologyLayers}
          isEcologyView={true}
        />
      </div>
    );
  }
}

/**
 * Map the received store to properties that are passed to the component.
 */
function mapStoreToProps(store: IStore): IStoreProps {
  return {
    areaCenter: store.area.center,
    areaShape: store.area.shape,
    ecologyLayers: store.ecologyLayers
  };
}

export default connect<IStoreProps, IParentProps>(mapStoreToProps)(
  MapEcology
);
