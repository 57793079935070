import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';

import Button from '../shared/button/button';
import closeLegend from '../../actions/close-legend';

import {areaTypeIdSelector} from '../../selectors/area';
import {showLegendSelector} from '../../selectors/show-legend';
import {viewSelector} from '../../selectors/view';
import {ViewIds} from '../../constants/views';

import {poiCategories} from '../../constants/pois';
import {poiCategoryIds} from '../../constants/location-competition';

import PoisLegend from '../shared/pois-legend';

import styles from './location-competition.styl';

/**
 * The location competition view
 */
const LocationCompetition = (): JSX.Element | null => {
  const areaTypeId = useSelector(areaTypeIdSelector);
  const showLegend = useSelector(showLegendSelector);
  const view = useSelector(viewSelector);
  const dispatch = useDispatch();

  const selectedPoiCategories = poiCategories.filter(poiCategory =>
    poiCategoryIds.includes(poiCategory.id)
  );

  return (
    <>
      {showLegend && view === ViewIds.LocationCompetition && (
        <section className={styles.locationCompetition}>
          <PoisLegend
            areaTypeId={areaTypeId}
            className={styles.locationCompetitionPoisLegend}
            poiCategories={selectedPoiCategories}
          />
          <Button
            variant="icon"
            className={styles.locationCompetitionLegendCloseButton}
            onClick={(): void => {
              dispatch(closeLegend());
            }}>
            <CloseIcon className={styles.colorGrey} />
          </Button>
          <footer className={styles.locationCompetitionPoisLegendAttribution}>
            <div className={styles.locationCompetitionPoisLegendAttributionBy}>
              Quelle: OpenStreetMap, Statistisches Bundesamt
            </div>
          </footer>
        </section>
      )}
    </>
  );
};

export default LocationCompetition;
