import {IStore} from '../../interfaces/store';
import React from 'react';
import {connect} from 'react-redux';

import getShapeBounds from '../../libs/get-shape-bounds';
import locationCompetitionPoisSelector from '../../selectors/location-competition-pois';

import Canvas from './canvas';
import AreaShape from './area-shape';
import Pois from './pois';
import PopulationDensityOverlay from './population-density-overlay';

interface IStoreProps {
  areaCenter: google.maps.LatLngLiteral | null;
  areaShape: google.maps.LatLngLiteral[] | null;
  pois: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
}

type IProps = IStoreProps;
interface IState {
  mapCanvas: google.maps.Map | null; // eslint-disable-line no-use-before-define
}

/**
 * The map container
 */
class Map extends React.Component<IProps, IState> {
  /**
   * The map state
   */
  state: IState = {
    mapCanvas: null
  };

  /**
   * Handle the init of the Google Maps Canvas
   */
  handleMapCanvasInit(mapCanvas: google.maps.Map): void {
    this.setState({mapCanvas});
  }

  /**
   * Render the Component
   */
  render(): JSX.Element | null {
    const {areaShape, areaCenter, pois} = this.props;
    const {mapCanvas} = this.state;

    if (!areaShape || !areaCenter) {
      return null;
    }

    const bounds = getShapeBounds(areaShape, 0.7);

    return (
      <div className="map">
        <Canvas
          bounds={bounds}
          center={areaCenter}
          zoom={14}
          printVersion={true}
          onInit={this.handleMapCanvasInit.bind(this)}
        />

        <AreaShape
          mapCanvas={this.state.mapCanvas}
          shape={this.props.areaShape}
        />

        <PopulationDensityOverlay mapCanvas={mapCanvas} show={true} />

        <Pois mapCanvas={mapCanvas} pois={pois} />
      </div>
    );
  }
}

/**
 * Map the received store to properties that are passed to the component.
 */
function mapStoreToProps(store: IStore): IStoreProps {
  return {
    areaCenter: store.area.center,
    areaShape: store.area.shape,
    pois: locationCompetitionPoisSelector(store)
  };
}

export default connect(mapStoreToProps)(Map);
