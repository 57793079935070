/**
 * Get the bounds of a shape
 */
export default function(
  shape: google.maps.LatLngLiteral[],
  buffer?: number
): google.maps.LatLngBoundsLiteral {
  const bounds: google.maps.LatLngBoundsLiteral = {
    east: -Infinity,
    north: -Infinity,
    south: Infinity,
    west: Infinity
  };

  shape.forEach(coordinate => {
    if (coordinate.lat > bounds.north) {
      bounds.north = coordinate.lat;
    }

    if (coordinate.lat < bounds.south) {
      bounds.south = coordinate.lat;
    }

    if (coordinate.lng > bounds.east) {
      bounds.east = coordinate.lng;
    }

    if (coordinate.lng < bounds.west) {
      bounds.west = coordinate.lng;
    }
  });

  if (buffer) {
    const latDiff = (bounds.north - bounds.south) * buffer;
    const lngDiff = (bounds.east - bounds.west) * buffer;
    bounds.north = bounds.north + latDiff;
    bounds.south = bounds.south - latDiff;
    bounds.east = bounds.east + lngDiff;
    bounds.west = bounds.west - lngDiff;
  }

  return bounds;
}
