import {IRoute} from '../../interfaces/route';
import {IStore} from '../../interfaces/store';
import React from 'react';
import {connect} from 'react-redux';

import getShapeBounds from '../../libs/get-shape-bounds';

import Canvas from './canvas';
import AreaShape from './area-shape';
import TrafficRoutes from './traffic-routes';

interface IStoreProps {
  areaCenter: google.maps.LatLngLiteral | null;
  areaShape: google.maps.LatLngLiteral[] | null;
  trafficRoutes: IRoute[];
}
type IProps = IStoreProps;
interface IState {
  mapCanvas: google.maps.Map | null; // eslint-disable-line no-use-before-define
}

/**
 * The map container
 */
class Map extends React.Component<IProps, IState> {
  /**
   * The map state
   */
  state: IState = {
    mapCanvas: null
  };

  /**
   * Handle the init of the Google Maps Canvas
   */
  handleMapCanvasInit(mapCanvas: google.maps.Map): void {
    this.setState({mapCanvas});
  }

  /**
   * Render the Component
   */
  render(): JSX.Element | null {
    const {areaShape, areaCenter} = this.props;
    const {mapCanvas} = this.state;

    if (!areaShape || !areaCenter) {
      return null;
    }

    const bounds = getShapeBounds(
      // @ts-ignore flat() is available on arrays nowadays
      [...areaShape, ...this.props.trafficRoutes.map(({ path }) => path).flat()]);

    return (
      <div className="map">
        <Canvas
          bounds={bounds}
          center={areaCenter}
          zoom={14}
          printVersion={true}
          onInit={this.handleMapCanvasInit.bind(this)}
        />

        <AreaShape
          mapCanvas={this.state.mapCanvas}
          shape={this.props.areaShape}
        />

        <TrafficRoutes
          mapCanvas={mapCanvas}
          show={true}
          routes={this.props.trafficRoutes}
        />
      </div>
    );
  }
}

/**
 * Map the received store to properties that are passed to the component.
 */
function mapStoreToProps(store: IStore): IStoreProps {
  return {
    areaCenter: store.area.center,
    areaShape: store.area.shape,
    trafficRoutes: store.traffic.routes
  };
}

export default connect(mapStoreToProps)(Map);
