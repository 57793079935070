import React, {useState, useEffect} from 'react'; // eslint-disable-line no-unused-vars, max-len
import {polygonOptions} from '../../config/area-shape'; // eslint-disable-line no-unused-vars, max-len
import {useRouteMatch} from 'react-router-dom';

interface IProps {
  mapCanvas: google.maps.Map | null;
  shape: google.maps.LatLngLiteral[] | null;
  onChange?: (shape: google.maps.LatLngLiteral[]) => void;
  hide?: boolean;
}

/**
 * The area shape that can be drawn on the map
 */
const AreaShape = ({
  mapCanvas,
  shape,
  onChange,
  hide
}: IProps): JSX.Element | null => {
  /**
   * The drawing manager that handles the polygon drawing
   */
  const [
    drawingManager,
    setDrawingManager
  ] = useState<google.maps.drawing.DrawingManager | null>(null);

  /**
   * The polygon of the shape
   */
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

  /**
   * is area current configuration step
   */
  const isStepArea = useRouteMatch('/config/area');

  /**
   * Exit the editing mode
   */
  const exitEditingMode = (
    currentDrawingManager: google.maps.drawing.DrawingManager
  ): void => {
    currentDrawingManager.set('map', null);
    setDrawingManager(null);
  };

  /**
   * When the polygon got created
   */
  const handlePolygonCreated = (newPolygon: google.maps.Polygon): void => {
    const path = newPolygon.getPath();
    const latLngs: google.maps.LatLng[] = path.getArray();
    const newShape: google.maps.LatLngLiteral[] = latLngs.map(latLng =>
      latLng.toJSON()
    );
    newPolygon.setPath(newShape);

    if (mapCanvas) {
      newPolygon.set('map', mapCanvas);
    }
    setPolygon(newPolygon);

    if (onChange) {
      onChange(newShape);
    }
  };

  /**
   * Enable the editing mode
   */
  const enableEditingMode = (map: google.maps.Map): void => {
    // eslint-disable-line no-unused-vars, max-len
    const newDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      map,
      polygonOptions
    });

    const startDrawingmanager = (): void => {
      newDrawingManager.addListener('polygoncomplete', newPolygon => {
        handlePolygonCreated(newPolygon);
      });
      setDrawingManager(newDrawingManager);
      window.removeEventListener('touchstart', startDrawingmanager, false);
    };

    if ('ontouchstart' in window) {
      window.addEventListener('touchstart', startDrawingmanager);
    } else {
      startDrawingmanager();
    }
  };

  /**
   * Handle adding or removing existing shape
   */
  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    if (shape && !polygon) {
      setPolygon(
        new google.maps.Polygon(
          Object.assign({}, polygonOptions, {
            map: mapCanvas,
            path: shape
          })
        )
      );
    } else if (!shape && polygon) {
      polygon.set('map', null);
    }
  }, [mapCanvas, shape]);

  /**
   * Handle drawing new shape
   */
  useEffect(() => {
    if (mapCanvas && isStepArea && !drawingManager && !shape && !hide) {
      enableEditingMode(mapCanvas);
    }
    return (): void => {
      if (drawingManager && ((isStepArea && shape) || hide)) {
        exitEditingMode(drawingManager);
      }
    };
  }, [mapCanvas, isStepArea, shape, hide]);

  return null;
};

export default AreaShape;
