import React from 'react';

import LineChart from '../../chart-views/workplace-development/line-chart';
import PieChart from '../../chart-views/workplace-development/pie-chart';
import {useSelector} from 'react-redux';
import workplaceDevelopment from '../../../selectors/workplace-development';
import workplaceStructure from '../../../selectors/workplace-structure';
import {areaSelector} from '../../../selectors/area';
import {labelMap} from '../../../constants/workplace-development';
import PrintPage from '../print-page/print-page';
import PrintParagraph from '../print-paragraph/print-paragraph';

const Workplace = (): JSX.Element | null => {
  const area = useSelector(areaSelector);
  const developmentData = useSelector(workplaceDevelopment);
  const structureData = useSelector(workplaceStructure);

  if (!developmentData || !structureData || !area) {
    return null;
  }

  return (
    <>
      <PrintPage title="Arbeitsplätze">
        <PrintParagraph
          aside={
            <>
              <div>Grundsätzlicher Hinweis</div>
              <div>Gesamtzahl an Arbeitsplätzen</div>
            </>
          }
          main={
            <>
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '16px',
                  marginBottom: '10mm'
                }}>
                Die Vorhersage der Zahl und Strukturen von Arbeitsplätzen ohne
                genauere Kenntnis der voraussichtlichen Unternehmensansiedlungen
                ist grundsätzlich mit einer hohen Unsicherheit behaftet. Alle
                nachfolgenden Werte sind daher als grobe Orientierungswerte zu
                verstehen und zeigen die Verhältnisse auf strukturell ähnlichen
                Gewerbeflächen in Deutschland. Genauere Erläuterungen finden
                sich im separaten Dokument „Methodik“.
              </p>

              <LineChart data={developmentData} print />
              {Number(area.influx) > 1 && (
                <p>
                  Anstieg in den Anfangsjahren aufgrund der angenommenen Dauer
                  des Bezugs des Gebiets ({area.influx} Jahre laut
                  Projektdefinition).
                </p>
              )}
            </>
          }
        />
      </PrintPage>
      <PrintPage title="Arbeitsplätze (Fortsetzung)">
        <PrintParagraph
          aside={<p>Branchenstruktur</p>}
          main={
            <>
              <PieChart data={structureData} />
              <p>
                Bei dieser mittleren Branchenstruktur würde sich die auf der
                vorigen Seite genannte Gesamtzahl der Arbeitsplätze (nach
                Vollbezug) rechnerisch wie folgt zusammensetzen:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Gewerbe</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(structureData.entries()).map(([key, value]) => (
                    <tr key={key}>
                      <td>{labelMap.get(key)}</td>
                      <td>
                        {Math.round(value).toLocaleString('de')} Arbeitsplätze
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          }
        />
      </PrintPage>
    </>
  );
};

export default Workplace;
