import React, {useEffect, useState} from 'react';
import sizeme from 'react-sizeme';

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Hint
} from 'react-vis';

import Loading from '../shared/loading/loading';
import Legend from '../shared/legend';

const tickValues: number[] = [80, 85, 90, 95, 100, 105, 110, 115, 120];

import {getExtremeBy} from '../../libs/math-functions';
import createInvisibleLineData from '../../libs/create-invisible-line-data';
import colors from '../../constants/colors';

import {IMunicipalityData} from '../../interfaces/municipality-data';
import {IGraphDatum} from '../../interfaces/graph-datum';
import {IYearValue} from '../../interfaces/year-value';
import clsx from 'clsx';
import styles from './recent-development.styl';

interface IProps {
  data: IMunicipalityData | null;
  size: {width: number; height: number};
  print?: boolean;
}

const useData = (
  data: IMunicipalityData | null
): {
  minYear: any;
  maxYear: any;
  domain: [number, number] | null;
  graphs: {x: number; y: number; absolute: number}[][] | null;
  invisibleLineData: IGraphDatum<number, number>[] | null;
} => {
  const [invisibleLineData, setInvisibleLineData] = useState<
    IGraphDatum<number, number>[] | null
  >(null);
  const [maxYear, setMaxYear] = useState<IYearValue>({value: 0, year: 0});
  const [minYear, setMinYear] = useState<IYearValue>({value: 0, year: 0});
  const [domain, setDomain] = useState<[number, number] | null>(null);
  const [graphs, setGraphs] = useState<
    {x: number; y: number; absolute: number}[][] | null
  >(null);

  useEffect(() => {
    if (data) {
      const inhabitantsData: IYearValue[][] = data.inhabitants;
      setMinYear(getExtremeBy(inhabitantsData, 'min', 'year'));
      setMaxYear(getExtremeBy(inhabitantsData, 'max', 'year'));
    }
  }, [data]);

  useEffect(() => {
    if (minYear.value && maxYear.value && data) {
      const minByValue: IYearValue = getExtremeBy(
        data.inhabitants,
        'min',
        'value'
      );
      const maxByValue: IYearValue = getExtremeBy(
        data.inhabitants,
        'max',
        'value'
      );
      const minPercentage: number = (minByValue.value / minYear.value) * 100;
      const maxPercentage: number = (maxByValue.value / minYear.value) * 100;
      const minDomainRaw: number = Math.floor(minPercentage / 5) * 5;
      const minDomain: number = Math.min(minDomainRaw, 95);
      const maxDomain: number = Math.floor(maxPercentage / 5 + 1) * 5;

      setDomain([minDomain, maxDomain]);

      setGraphs(
        data.inhabitants.map((graph: IYearValue[]): {
          x: number;
          y: number;
          absolute: number;
        }[] =>
          graph.map((yearValue: IYearValue): {
            x: number;
            y: number;
            absolute: number;
          } => ({
            x: yearValue.year,
            y: (yearValue.value / minYear.value) * 100,
            absolute: yearValue.value
          }))
        )
      );

      setInvisibleLineData(createInvisibleLineData(minYear.year, maxYear.year));
    }
  }, [minYear, maxYear, data]);

  return {
    minYear: minYear.year,
    maxYear: maxYear.year,
    domain,
    graphs,
    invisibleLineData
  };
};

const handleNearestX = (
  hoveredDatum: IGraphDatum<number, number>,
  graphs
): IGraphDatum<number, number> | null => {
  if (!hoveredDatum) {
    return null;
  }

  const year: number = hoveredDatum.x;
  const values: (
    | IGraphDatum<number, number>
    | undefined
  )[] = graphs.map(graph => graph.find(graphDatum => graphDatum.x === year));
  let hoveredValue: IGraphDatum<number, number> | null = values[0] || null;

  if (!hoveredValue) {
    hoveredValue = values[1] || null;
  }

  return hoveredValue;
};

const Inhabitants = (props: IProps): JSX.Element => {
  const [hoveredValue, setHoveredValue] = useState<IGraphDatum<
    number,
    number
  > | null>(null);
  const {minYear, maxYear, domain, graphs, invisibleLineData} = useData(
    props.data
  );
  if (!minYear || !maxYear || !domain || !graphs || !invisibleLineData) {
    return <Loading />;
  }

  const marginXYPlot = props.print
    ? {left: 60, right: 50, top: 10, bottom: 40}
    : {left: 80, right: 30, top: 10, bottom: 40};

  return (
    <div>
      <p>
        Einwohnerentwicklung {minYear} bis {maxYear} (Indexwert).
      </p>
      <p>Einwohnerzahl von {minYear} entspricht dem Indexwert 100.</p>

      <XYPlot
        width={props.print ? 750 : props.size.width}
        height={266}
        margin={marginXYPlot}
        yDomain={domain}
        onMouseLeave={() => setHoveredValue(null)}>
        <HorizontalGridLines tickValues={tickValues} />

        {graphs.map(
          (graph, index): JSX.Element => (
            <LineSeries
              key={graph[0].x + graph[0].y}
              data={graph}
              color={index === 0 ? colors.primaryColor : colors.lightBlue}
            />
          )
        )}

        <LineSeries
          data={invisibleLineData}
          color="transparent"
          onNearestX={data => setHoveredValue(handleNearestX(data, graphs))}
        />

        <XAxis
          tickTotal={maxYear - minYear}
          tickFormat={(tick: number): number => Number(tick)}
        />

        <YAxis tickValues={tickValues} />

        {!props.print && (
          <span className="visualization--y-axis-label-rotated visualization--y-axis-label-distance-to-graph ">
            Einwohnerzahl Index {minYear} = 100
          </span>
        )}

        {hoveredValue && !props.print && (
          <Hint
            value={hoveredValue}
            align={{
              horizontal: Hint.ALIGN.RIGHT,
              vertical: Hint.ALIGN.TOP
            }}>
            <div className="visualization__hint">
              <span className="visualization__hint__value">
                {hoveredValue.absolute &&
                  hoveredValue.absolute.toLocaleString('de')}
              </span>
              <span className="visualization__hint__year">
                {hoveredValue.x}
              </span>
            </div>
          </Hint>
        )}
      </XYPlot>

      {!props.print && (
        <Legend
          entries={[
            {
              color: colors.primaryColor,
              description: 'vor Zensus-Bereinigung 2011'
            },
            {
              color: colors.lightBlue,
              description: 'nach Zensus-Bereinigung 2011'
            }
          ]}
        />
      )}

      {!props.print && (
        <p className={clsx(styles.note, styles.hideOnMobile)}>
          Wenn Sie mit der Maus über das Diagramm fahren, werden Ihnen die
          Absolutwerte angezeigt.
        </p>
      )}
    </div>
  );
};

export default sizeme()(Inhabitants);
