import React, {useEffect, useRef, useState} from 'react';

import EcologyOverlayInfowindow from './ecology-overlay-infowindow';
import {IEcologyLayer} from '../../interfaces/ecology';
import {Ecologies} from '../../constants/ecologies';
import {ViewIds} from '../../constants/views';
import fetchEcologyLayerInformation, {
  removeEcologyLayerInformation
} from '../../actions/ecology/fetch-ecology-layer-information';

import WmsMapType from '../../libs/wms-map-type';
import WfsMapType from '../../libs/wfs-map-type';
import {useDispatch, useSelector} from 'react-redux';
import ecologyLayerInformationSelector from '../../selectors/ecology-layer-information';

interface IProps {
  ecologyLayers: Map<ViewIds, IEcologyLayer>;
  view: ViewIds;
  isEcologyView: boolean;
  mapCanvas: google.maps.Map | null;
}

const clickHandler = (
  dispatch,
  setToolTipPosition,
  mapCanvas,
  ecology
): ((event: any) => void) => (event): void => {
  dispatch(removeEcologyLayerInformation());

  const layers = ecology.data.layers
    .map(({id}) => `projectcheck:${id}`)
    .join(',');
  const {width, height} = mapCanvas.getDiv().getBoundingClientRect();

  setToolTipPosition(event.latLng.toJSON());

  dispatch(
    fetchEcologyLayerInformation({
      bounds: mapCanvas.getBounds().toJSON(),
      layers,
      x: event.pixel.x,
      y: event.pixel.y,
      width,
      height
    })
  );
};

const EcologyOverlay = ({
  isEcologyView,
  view,
  mapCanvas
}: IProps): JSX.Element | null => {
  const ecology = Ecologies.get(view);
  const dispatch = useDispatch();
  const [tooltipPosition, setToolTipPosition] = useState<
  google.maps.LatLngLiteral
  >({lat: 0, lng: 0});
  const overlay = useRef<WmsMapType | WfsMapType | null>(null);
  const listener = useRef<google.maps.MapsEventListener | null>(null);
  const data = useSelector(ecologyLayerInformationSelector);

  useEffect((): (() => void) => {
    if (ecology && mapCanvas && isEcologyView) {
      overlay.current =
        ecology.serviceType === 'wms'
          ? new WmsMapType(ecology.shortName, ecology.data)
          : new WfsMapType(ecology.shortName, ecology.data);
      overlay.current.addToMap(mapCanvas);

      listener.current = mapCanvas.addListener(
        'click',
        clickHandler(dispatch, setToolTipPosition, mapCanvas, ecology)
      );
    }

    return (): void => {
      if (overlay.current && mapCanvas) {
        overlay.current.removeFromMap(mapCanvas);

        overlay.current = null;
      }

      if (listener.current) {
        listener.current.remove();
      }
      dispatch(removeEcologyLayerInformation());
    };
  }, [ecology, mapCanvas, isEcologyView]);

  if (data && mapCanvas) {
    return (
      <EcologyOverlayInfowindow
        data={data}
        mapCanvas={mapCanvas}
        position={tooltipPosition}
      />
    );
  }

  return null;
};

export default EcologyOverlay;
