import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import fetchMunicipalities from '../actions/municipalities/fetch-municipalities';
import reducers from './index';

import {
  SELECT_VIEW,
  DOWNLOAD,
  IMPORT_SETTINGS,
  START_ANALYSE
} from '../constants/actions';
import {ViewTitles} from '../constants/views';

/**
 * Intercepts actions and tracks them for google analytics
 */

const trackingMiddleware = () => next => (action): void => {
  if ([DOWNLOAD, IMPORT_SETTINGS, START_ANALYSE].includes(action.type)) {
    ga('send', 'event', 'interaction', action.type);
  }

  if ([SELECT_VIEW].includes(action.type)) {
    ga('send', 'pageview', ViewTitles.get(action.viewId));
  }

  return next(action);
};

const middlewares = [thunk, trackingMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
    timestamp: false
  });

  // @ts-ignore
  middlewares.push(logger);
}

const store = createStore(
  reducers,

  applyMiddleware(...middlewares)
);

// @ts-ignore
store.dispatch(fetchMunicipalities());

export default store;
