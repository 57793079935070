import { IEcology, IEcologyLayer } from '../interfaces/ecology';
import xmlParser from '../libs/parser.min';

/**
 * Load the ecology layer data
 */
export default function (ecology: IEcology): Promise<IEcologyLayer> {
  return new Promise((resolve, reject): void => {
    const urlOptions = {
      service: 'WMS',
      request: 'GetCapabilities'
    };

    const urlOptionsAsQueryString: string = Object.entries(urlOptions)
      .map((keyValuePair: string[]) => keyValuePair.join('='))
      .join('&');

    const url = `${ecology.data.url}?${urlOptionsAsQueryString}`;

    fetch(url, { mode: 'cors' })
      .then(response => response.text())
      .then((xml: string) => {
        const data = xmlParser.parse(xml);
        const layers = data.WMS_Capabilities.Capability.Layer.Layer;

        if (!layers || !Array.isArray(layers)) {
          reject(new Error('Wrong data loaded.'));
          return;
        }

        // eslint-disable-next-line
        resolve({
          year: data.WMS_Capabilities.Service.Abstract.match(
            /(\d{2,4}\.?){3}/
          )[0]
        } as IEcologyLayer);
      })
      .catch(error => reject(error));
  });
}
