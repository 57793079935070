import {IAction} from '../../interfaces/action';
import {UPDATE_MAP_BOUNDS} from '../../constants/actions';

/**
 * Update the bounds
 */
export default function(mapBounds: google.maps.LatLngBoundsLiteral): IAction {
  return {
    type: UPDATE_MAP_BOUNDS,
    mapBounds
  };
}
