import { IAction } from '../../interfaces/action';
import { IStore } from '../../interfaces/store';
import { SELECT_AREA_SHAPE } from '../../constants/actions';
import selectAreaSize from './select-area-size';
import CreateCheapRuler from 'cheap-ruler';

/**
 * Select an area shape
 */
export function selectAreaShape(
  areaShape: google.maps.LatLngLiteral[]
): IAction {
  return {
    type: SELECT_AREA_SHAPE,
    areaShape
  };
}

/**
 * Select an area shape and maybe calculate the size
 */
export default function (areaShape: google.maps.LatLngLiteral[]) {
  return (dispatch, getState: () => IStore) => {
    dispatch(selectAreaShape(areaShape));

    const size = getState().area.size;

    if (size) {
      return;
    }

    const cheapRuler = new CreateCheapRuler(areaShape[0].lat);
    const area: number = cheapRuler.area([
      areaShape.map(
        (point): [number, number] => {
          return [point.lng, point.lat];
        }
      )
    ]);
    const areaInHa: number = area * 100;
    const roundedAreaInHa: number = Math.round(areaInHa * 10) / 10;

    dispatch(selectAreaSize(roundedAreaInHa));
  };
}
