import React, {useEffect} from 'react';
import {Route, Switch, useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import SidebarHeader from '../sidebar-header/sidebar-header';
import SidebarFooter from '../sidebar-footer/sidebar-footer';
import Instruction from '../instruction/instruction';
import Stepper from '../sidebar-footer/stepper/stepper';
import ConfigurationTitle from './configuration-title/configuration-title';
import ConfigurationMunicipality from './configuration-municipality/configuration-municipality';
import ConfigurationUsage from './configuration-usage/configuration-usage';
import ConfigurationDetails from './configuration-details/configuration-details';

// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';

import Map from '../map/map';

import configurationStepNumber from '../../selectors/configuration-step-number';

import styles from './configuration.styl';

import {steps} from '../../config/steps';

const Configuration = ({hide = false}: {hide?: boolean}): JSX.Element => {
  const {configurationStep} = useParams();
  const currentStepNumber = useSelector(configurationStepNumber);
  const history = useHistory();
  const isDesktop = useMediaQuery(mediaQueries.big);

  useEffect(() => {
    if (
      !configurationStep ||
      steps[configurationStep].number > currentStepNumber
    ) {
      history.push('/welcome');
    } else if (steps[configurationStep].number === steps.done.number) {
      history.push('/views');
    }
  }, [configurationStep]);

  return (
    <aside className={clsx(styles.container, hide && styles.noDisplay)}>
      <div>
        <SidebarHeader title="Neue Planung" />
        <ConfigurationTitle />
      </div>
      {!isDesktop && <Map hide={configurationStep !== steps.area.id} />}
      <Switch>
        <Route path={'/config/municipality'}>
          <ConfigurationMunicipality />
        </Route>
        <Route path={'/config/area'} />
        <Route path={'/config/details'}>
          <ConfigurationDetails />
        </Route>
        <Route path={'/config/usage'}>
          <ConfigurationUsage />
        </Route>
      </Switch>
      <div>
        <Instruction isDesktop={isDesktop} />
        <SidebarFooter>
          <Stepper
            stepOnBlocked={
              !configurationStep ||
              currentStepNumber <= steps[configurationStep].number
            }
          />
        </SidebarFooter>
      </div>
    </aside>
  );
};

export default Configuration;
