import React, {useRef} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {startAnalyse} from '../../../actions/settings/start-analyse';

import {steps} from '../../../config/steps';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

interface IProps {
  stepOnBlocked: boolean;
}

const useStyles = makeStyles({
  root: {
    fontSize: '15px',
    fontWeight: 700
  }
});

const Stepper = ({stepOnBlocked}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {configurationStep} = useParams();
  const previousRoute = useRef(null);

  const stepsArray = Object.keys(steps);
  const activeStepNumber =
    configurationStep && steps[configurationStep].number - 1;

  const navigateNextTarget = `/config/${stepsArray[activeStepNumber + 1]}`;
  const navigateBackTarget = `/config/${stepsArray[activeStepNumber - 1]}`;
  const classes = useStyles();

  return (
    <MobileStepper
      color="primary"
      variant="dots"
      steps={4}
      position="static"
      activeStep={activeStepNumber}
      nextButton={
        <Button
          className={classes.root}
          disableRipple
          size="large"
          color="primary"
          component={Link}
          onClick={(): void => {
            if (
              previousRoute.current === '/config/details' &&
              history.location.pathname === '/config/usage'
            ) {
              dispatch(startAnalyse());
            }

            previousRoute.current = history.location.pathname;
          }}
          to={navigateNextTarget}
          disabled={stepOnBlocked}>
          Weiter
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="large"
          className={classes.root}
          disableRipple
          color="primary"
          component={Link}
          to={navigateBackTarget}
          disabled={activeStepNumber === 0}>
          <KeyboardArrowLeft />
          Zurück
        </Button>
      }
    />
  );
};

export default Stepper;
