import {ViewIds} from './views';
import {IEcology} from '../interfaces/ecology';

/**
 * The various ecologies overlays
 */
export const Ecologies = new Map<ViewIds, IEcology>();
Ecologies.set(ViewIds.EcologyLandscapeProtection, {
  shortName: 'Landschaftsschutz',
  description:
    'Schutzgebiete mit Schwerpunkt "Landschaftsschutz" im Umfeld des Plangebiets',
  copyright: `Bundesamt für Naturschutz (BfN), Stand: 2017-2019`,
  serviceType: 'wms',
  data: {
    url: 'https://projektcheck.gs.ils-geomonitoring.de/geoserver/projektcheck/wms?',
    layers: [
      {
        id: 'lsg_2017',
        name: 'Landschaftsschutzgebiete'
      },
      {
        id: 'bio2019',
        name: 'Biosphärenreservate (UNESCO)'
      },
      {id: 'naturparke2019', name: 'Naturparke'}
    ]
  }
});

Ecologies.set(ViewIds.EcologySpeciesProtection, {
  shortName: 'Naturschutz',
  description:
    'Schutzgebiete mit Schwerpunkt "Naturschutz" im Umfeld des Plangebiets',
  copyright: `Bundesamt für Naturschutz (BfN), Stand: 2017-2019`,
  serviceType: 'wms',
  data: {
    url: 'https://projektcheck.gs.ils-geomonitoring.de/geoserver/projektcheck/wms?',
    layers: [
      {
        id: 'nnm_2019',
        name: 'Nationale Naturmonumente'
      },
      {id: 'nsg_2017', name: 'Naturschutzgebiete'},
      {id: 'nlp2019', name: 'Nationalparke'},
      {id: 'spa_de_2018', name: 'Vogelschutzgebiete'},
      {
        id: 'ffh_de_2018',
        name: 'FFH-Gebiete'
      }
    ]
  }
});

Ecologies.set(ViewIds.EcologyHighVoltageSupplyLines, {
  shortName: 'Hochspannung',
  description: 'Hochspannungsfreileitungen im Umfeld des Plangebiets',
  copyright: `Bundesamt für Kartographie und Geodäsie (BKG)`,
  serviceType: 'wfs',
  data: {
    url: 'https://sg.geodatenzentrum.de/wfs_dlm250',
    layers: [
      {
        id: 'dlm250:objart_51005_l',
        name: 'Hochspannungsfreileitungen',
        color: '#000000'
      }
    ]
  }
});
