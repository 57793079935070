import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import AreaDensity from './area-density/area-density';
import AreaInflux from './area-influx/area-influx';
import AreaType from './area-type/area-type';

import {areaSelector} from '../../../selectors/area';
import selectAreaTypeId from '../../../actions/area/select-area-type-id';
import selectAreaInflux from '../../../actions/area/select-area-influx';
import selectAreaDensityId from '../../../actions/area/select-area-density-id';
import setAreaConfig from '../../../actions/area/set-area-config';
import {areaConfigsById} from '../../../constants/area-config';
import {AreaTypeIds} from '../../../constants/area-types';

import styles from './configuration-usage.styl';

const ConfigurationUsage = (): JSX.Element => {
  const dispatch = useDispatch();
  const area = useSelector(areaSelector);
  const [selectedTypeId, setSelectedTypeId] = useState<AreaTypeIds>();

  return (
    <div className={styles.container}>
      <div className={styles.dropdown}>
        <AreaType
          typeId={area.typeId}
          onChange={(typeId): void => {
            setSelectedTypeId(typeId);
            dispatch(selectAreaTypeId(typeId));
          }}
        />
        <AreaDensity
          areaTypeId={area.typeId}
          densityId={area.densityId}
          disabled={area.typeId === null}
          onChange={(densityId): void => {
            dispatch(selectAreaDensityId(densityId));
            dispatch(setAreaConfig(areaConfigsById[densityId]));
          }}
        />
        {selectedTypeId !== AreaTypeIds.Retail && (
          <AreaInflux
            influx={area.influx}
            disabled={area.typeId === null || area.densityId === null}
            onChange={(influx): void => {
              dispatch(selectAreaInflux(influx));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ConfigurationUsage;
