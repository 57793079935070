import {IAction} from '../interfaces/action';

import {
  FETCH_POPULATION_STRUCTURE,
  FETCH_POPULATION_STRUCTURE_SUCCESS,
  FETCH_POPULATION_STRUCTURE_ERROR
} from '../constants/actions';
import fetchPopulationStructureApi from '../apis/fetch-population-structure';

/**
 * Fetching the population structure
 */
export function fetchPopulationStructure(): IAction {
  return {
    type: FETCH_POPULATION_STRUCTURE
  };
}

/**
 * Success while fetching the population structure
 */
export function fetchPopulationStructureSuccess(
  populationStructure: any
): IAction {
  return {
    type: FETCH_POPULATION_STRUCTURE_SUCCESS,
    populationStructure
  };
}

/**
 * Error while fetching the population structure
 */
export function fetchPopulationStructureError(error: Error): IAction {
  return {
    type: FETCH_POPULATION_STRUCTURE_ERROR,
    error
  };
}

/**
 * Fetch the population structure
 */
export default function(
  fetchPopulationStructureRemotely: () => Promise<
  any
  > = fetchPopulationStructureApi
) {
  return dispatch => {
    dispatch(fetchPopulationStructure());

    return fetchPopulationStructureRemotely()
      .then(populationStructure =>
        dispatch(fetchPopulationStructureSuccess(populationStructure))
      )
      .catch(error => dispatch(fetchPopulationStructureError(error)));
  };
}
