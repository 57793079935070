import {IAction} from '../../interfaces/action';

import {
  FETCH_ECOLOGY_LAYER_INFORMATION,
  FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS,
  FETCH_ECOLOGY_LAYER_INFORMATION_ERROR,
  REMOVE_ECOLOGY_LAYER_INFORMATION
} from '../../constants/actions';
import fetchApi from '../../apis/fetch-ecology-layer-information';
import {IEcologyLayerInformation} from '../../interfaces/store';

interface IOptions {
  bounds: google.maps.LatLngBoundsLiteral;
  layers: string;
  x: number;
  y: number;
  width: number;
  height: number;
}
/**
 * Fetching the ecology layer
 */
export function fetchEcologyLayerInformation(): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER_INFORMATION
  };
}

/**
 * Success while fetching the ecology layer
 */
export function fetchEcologyLayerInformationSuccess(
  geoJSON: IEcologyLayerInformation
): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER_INFORMATION_SUCCESS,
    geoJSON
  };
}

/**
 * Error while fetching the ecology layer
 */
export function fetchEcologyLayerInformationError(error: Error): IAction {
  return {
    type: FETCH_ECOLOGY_LAYER_INFORMATION_ERROR,
    error
  };
}

/**
 * Error while fetching the ecology layer
 */
export function removeEcologyLayerInformation(): IAction {
  return {
    type: REMOVE_ECOLOGY_LAYER_INFORMATION
  };
}

/**
 * Fetch the layer data of a ecology view
 */
export default function(
  options: IOptions,
  fetchEcologyLayerInformationRemotely: (
    options: IOptions
  ) => Promise<IEcologyLayerInformation> = fetchApi
) {
  return dispatch => {
    dispatch(fetchEcologyLayerInformation());

    return fetchEcologyLayerInformationRemotely(options)
      .then(data => dispatch(fetchEcologyLayerInformationSuccess(data)))
      .catch(error => dispatch(fetchEcologyLayerInformationError(error)));
  };
}
