import React, {useState} from 'react';

import getShapeBounds from '../../libs/get-shape-bounds';

import Canvas from './canvas';
import AreaShape from './area-shape';
import {IArea} from '../../interfaces/store';

import styles from './map.styl';

type IProps = {area: IArea; onInit: () => void};

/**
 * The map container
 */
const Map = ({area, onInit}: IProps): JSX.Element | null => {
  const [mapCanvas, setMapCanvas] = useState<google.maps.Map | null>(null);

  if (!area.shape || !area.center) {
    return null;
  }

  const bounds = getShapeBounds(area.shape);

  return (
    <div className={styles.printMap}>
      <Canvas
        bounds={bounds}
        center={area.center}
        zoom={14}
        printVersion={true}
        onInit={canvas => {
          setMapCanvas(canvas);
          onInit();
        }}
      />

      {mapCanvas && <AreaShape mapCanvas={mapCanvas} shape={area.shape} />}
    </div>
  );
};

export default Map;
