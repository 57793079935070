import React, {useState} from 'react';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';

import Canvas from './canvas';
import AreaShape from './area-shape';
import EcologyOverlay from './ecology-overlay';
import Isochrones from './isochrones';
import Pois from './pois';
import TrafficRoutes from './traffic-routes';
import PopulationDensityOverlay from './population-density-overlay';
import MunicipalitiesOverlay from './municipalities-overlay';
import MapTypeControls from './map-type-controls/map-type-controls';

import addTrafficRoute from '../../actions/traffic/add-traffic-route';
import selectAreaShape from '../../actions/area/select-area-shape';
import getShapeBounds from '../../libs/get-shape-bounds';
import updateMapBounds from '../../actions/map/update-map-bounds';

import mapDataSelector from '../../selectors/map-data';

import {ViewIds} from '../../constants/views';
import {zoom, center} from '../../config/map';

import {AreaTypeIds} from '../../constants/area-types';

import styles from './map.styl';

/**
 * The map container
 */
const InteractiveMap = ({hide = false}: {hide?: boolean}): JSX.Element => {
  /**
   * The map state
   */
  const [mapCanvas, setMapCanvas] = useState<google.maps.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number | null>(null);

  const mapData = useSelector(mapDataSelector);
  const dispatch = useDispatch();

  /**
   * Handle the init of the Google Maps Canvas
   */
  const handleMapCanvasInit = (canvas: google.maps.Map): void => {
    setMapCanvas(canvas);
  };

  /**
   * Trigger creation of a new route
   */
  const addNewTrafficRoute = (destination: google.maps.LatLngLiteral): void => {
    if (!mapData.areaCenter) {
      return;
    }

    dispatch(addTrafficRoute(mapData.areaCenter, destination));
  };

  const {view, areaTypeId} = mapData;
  const isLocationCompetition = view === ViewIds.LocationCompetition;
  const isRetailReachability =
    view === ViewIds.TrafficReachability && areaTypeId === AreaTypeIds.Retail;
  const showPopulationDensityOverlay =
    isLocationCompetition || isRetailReachability;

  /**
   * Render the Component
   */
  return (
    <div className={clsx(styles.container, 'map', hide && styles.noDisplay)}>
      <Canvas
        bounds={mapData.bounds}
        center={center}
        zoom={zoom}
        onInit={handleMapCanvasInit}
        onZoomChange={setZoomLevel}
        hide={hide}
      />

      <MapTypeControls mapCanvas={mapCanvas} />

      <EcologyOverlay
        mapCanvas={mapCanvas}
        view={view}
        ecologyLayers={mapData.ecologyLayers}
        isEcologyView={mapData.isEcologyView}
      />

      <TrafficRoutes
        mapCanvas={mapCanvas}
        show={view === ViewIds.TrafficEnvironment}
        routes={mapData.trafficRoutes}
        onClick={addNewTrafficRoute}
      />

      <AreaShape
        mapCanvas={mapCanvas}
        shape={mapData.areaShape}
        onChange={(shape): void => {
          dispatch(selectAreaShape(shape));
          const mapBounds = getShapeBounds(shape, 2);
          dispatch(updateMapBounds(mapBounds));
        }}
        hide={hide}
      />

      <Isochrones
        mapCanvas={mapCanvas}
        isochrones={mapData.isochrones}
        show={view === ViewIds.TrafficReachability}
      />

      {[ViewIds.TrafficReachability, ViewIds.LocationCompetition].includes(
        view
      ) && <Pois mapCanvas={mapCanvas} pois={mapData.pois} />}

      <PopulationDensityOverlay
        mapCanvas={mapCanvas}
        show={showPopulationDensityOverlay}
      />

      <MunicipalitiesOverlay
        mapCanvas={mapCanvas}
        zoom={zoomLevel}
        view={view}
      />
    </div>
  );
};

export default InteractiveMap;
