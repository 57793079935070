import {IAction} from '../../interfaces/action';
import {
  SELECT_AREA_SHAPE,
  SELECT_MUNICIPALITY,
  DELETE_AREA_SHAPE
} from '../../constants/actions';
import getPolygonCenter from '../../libs/get-polygon-center';

/**
 * Initial state
 */
const initialState = null;

/**
 * Handle the current selected area center
 */
export default function(
  state: google.maps.LatLngLiteral | null = initialState,
  action: IAction
): google.maps.LatLngLiteral | null {
  switch (action.type) {
    case SELECT_AREA_SHAPE: {
      if (!action.areaShape) {
        return state;
      }

      return getPolygonCenter(action.areaShape);
    }

    case SELECT_MUNICIPALITY:
      return initialState;

    case DELETE_AREA_SHAPE:
      return initialState;

    default:
      return state;
  }
}
