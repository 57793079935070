// Global polyfills to support IE11
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/array/flat';
import 'core-js/features/number/is-finite';

import 'whatwg-fetch';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import store from './reducers/store';

import App from './components/app';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('.app-container')
);
