import React, {useMemo, useEffect, useRef} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

interface IProps {
  influx: number | null;
  disabled: boolean;
  onChange: (influx: number) => void;
}

type optionType = {
  value: number;
  label: string;
};

const options = [
  {value: 1, label: '1 Jahr'},
  {value: 2, label: '2 Jahre'},
  {value: 3, label: '3 Jahre'},
  {value: 4, label: '4 Jahre'},
  {value: 5, label: '5 Jahre'},
  {value: 6, label: '6 Jahre'},
  {value: 7, label: '7 Jahre'},
  {value: 8, label: '8 Jahre'},
  {value: 9, label: '9 Jahre'},
  {value: 10, label: '10 Jahre'}
];

const getCurrentValue = (influx): optionType | null =>
  options.find(option => option.value === influx) || null;

/**
 * The configuration section for the area influx
 */
const AreaInflux = ({disabled, influx, onChange}: IProps): JSX.Element => {
  const selectedOption = useMemo(() => getCurrentValue(influx), [influx]);
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl disabled={disabled} variant="outlined">
      <InputLabel ref={inputLabel}>Bezugsdauer</InputLabel>
      <Select
        value={selectedOption ? selectedOption.value : ''}
        labelWidth={labelWidth}
        onChange={(data): void => onChange(Number(data.target.value))}>
        {options.map(option => (
          <MenuItem key={String(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AreaInflux;
