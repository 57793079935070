import React from 'react';
import {useSelector} from 'react-redux';
import {areaSelector} from '../../selectors/area';
import {municipalitySelector} from '../../selectors/configuration-municipality';

import HeaderScreen from './header-screen';

import {IMunicipality} from '../../interfaces/municipality';
import {IAreaConfig, areaConfigs} from '../../constants/area-config';
import {IAreaType, AreaTypeIds, areaTypes} from '../../constants/area-types';

export interface IHeaderProps {
  name: string | null;
  size: string;
  influx: string;
  config: IAreaConfig | null;
  type: IAreaType | null;
  typeId: AreaTypeIds | null;
  municipality: IMunicipality | null;
}

//eslint-disable-next-line complexity
const HeaderContainer = (): JSX.Element => {
  const area = useSelector(areaSelector);
  const municipality = useSelector(municipalitySelector);

  const areaProps: IHeaderProps = {
    name: area.name,
    size: (area.size && `${area.size.toLocaleString()} ha`) || '',
    influx: (area.influx && `${area.influx} Jahre`) || '',
    config: areaConfigs.find(config => config.id === area.densityId) || null,
    type: areaTypes.find(type => type.id === area.typeId) || null,
    typeId: area.typeId,
    municipality
  };

  return <HeaderScreen {...areaProps} />;
};

export default HeaderContainer;
