import React, {useMemo, useEffect, useRef} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import {AreaTypeIds, areaTypes} from '../../../../constants/area-types';

interface IProps {
  typeId: AreaTypeIds | null;
  onChange: (typeId: AreaTypeIds) => void;
}

const options = areaTypes.map(areaType => ({
  value: areaType.id,
  label: areaType.name
}));

type optionType = {
  value: AreaTypeIds | null;
  label: string;
};

const getCurrentValue = (typeId): optionType | null =>
  options.find(option => option.value === typeId) || null;

/**
 * The configuration section for the area type id
 */
const AreaType = ({typeId, onChange}: IProps): JSX.Element => {
  const selectedOption = useMemo(() => getCurrentValue(typeId), [typeId]);
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl variant="outlined" style={{marginBottom: '16px'}}>
      <InputLabel ref={inputLabel}>Gebietsart</InputLabel>
      <Select
        value={selectedOption ? selectedOption.value : ''}
        labelWidth={labelWidth}
        onChange={(data): void => onChange(Number(data.target.value))}>
        {options.map(option => (
          <MenuItem key={String(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AreaType;
