import React from 'react';
import Button from '../shared/button/button';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {viewSelector} from '../../selectors/view';

import {menuItems} from '../../constants/menu-items';
import styles from './information.styl';

const CloseButton = (): JSX.Element => {
  const history = useHistory();
  const view = useSelector(viewSelector);

  const hasChart = menuItems.find(item => item.viewId === view)?.hasChart;

  return (
    <div className={styles.buttonGroup}>
      <Button
        onClick={(): void => {
          history.push(`/views/${view}${hasChart ? '/charts' : ''}`);
        }}>
        Analyse anzeigen
      </Button>
    </div>
  );
};

export default CloseButton;
