import {IAction} from '../../interfaces/action';
import {IRoute} from '../../interfaces/route';
import {ADD_TRAFFIC_ROUTE, CLEAR_TRAFFIC_ROUTES} from '../../constants/actions';

/**
 * Initial state
 */
const initialState: IRoute[] = [];

/**
 * Handle the current traffic reachability routes
 */
export default function(
  state: IRoute[] = initialState,
  action: IAction
): IRoute[] {
  switch (action.type) {
    case ADD_TRAFFIC_ROUTE:
      if (!action.route) {
        return state;
      }

      return [...state, action.route];

    case CLEAR_TRAFFIC_ROUTES:
      return initialState;

    default:
      return state;
  }
}
