/**
 * Available view IDs
 */
export enum ViewIds {
  None,
  RecentDevelopment,
  FollowupCosts,
  TrafficReachability,
  TrafficEnvironment,
  EcologyLandscapeProtection,
  EcologySpeciesProtection,
  EcologyHighVoltageSupplyLines,
  EcologyForest,
  EcologyOpenSpace,
  EcologyFloorSealing,
  PopulationDevelopment,
  WorkplaceDevelopment,
  MunicipalIncome,
  LocationCompetition
}

export const ViewTitles = new Map<ViewIds, string>();

ViewTitles.set(ViewIds.None, 'none');
ViewTitles.set(ViewIds.RecentDevelopment, 'Letzte Änderungen');
ViewTitles.set(ViewIds.FollowupCosts, 'Folgekosten');
ViewTitles.set(ViewIds.TrafficReachability, 'Erreichbarkeit');
ViewTitles.set(ViewIds.TrafficEnvironment, 'Verkehr im Umfeld');
ViewTitles.set(ViewIds.EcologyLandscapeProtection, 'Landschaftsschutz');
ViewTitles.set(ViewIds.EcologySpeciesProtection, 'Naturschutz');
ViewTitles.set(
  ViewIds.EcologyHighVoltageSupplyLines,
  'Hochspannungsfreileitungen'
);
ViewTitles.set(ViewIds.EcologyForest, 'Wald');
ViewTitles.set(ViewIds.EcologyOpenSpace, 'openspace');
ViewTitles.set(ViewIds.EcologyFloorSealing, 'FloorSealing');
ViewTitles.set(ViewIds.PopulationDevelopment, 'Bewohner/innen');
ViewTitles.set(ViewIds.WorkplaceDevelopment, 'Arbeitsplätze');
ViewTitles.set(ViewIds.MunicipalIncome, 'Kommunale Einnahmen');
ViewTitles.set(ViewIds.LocationCompetition, 'Standortkonkurrenz');
