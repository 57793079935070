import {IEcologyLayerData} from '../../interfaces/ecology';
import {Ecologies} from '../../constants/ecologies';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {ViewIds} from '../../constants/views';
import {viewSelector} from '../../selectors/view';
import {showLegendSelector} from '../../selectors/show-legend';

import Button from '../shared/button/button';
import closeLegend from '../../actions/close-legend';
import InfoOverlay from '../information-overlay/information-overlay';

import CloseIcon from '@material-ui/icons/Close';
import {useMediaQuery} from '@material-ui/core';

// @ts-ignore
import mediaQueries from '../../../styles/media-queries.json';

import clsx from 'clsx';
import styles from './ecology.styl';

/**
 * An ecology view
 */
const Ecology = (): JSX.Element => {
  const ecology = Ecologies.get(useSelector(viewSelector));
  const view = useSelector(viewSelector);
  const showLegend = useSelector(showLegendSelector);
  const isDesktop = useMediaQuery(mediaQueries.big);

  const dispatch = useDispatch();

  const attributionClass = clsx(
    styles.attribution,
    ecology?.shortName === 'Hochspannung' && styles.attributionHighVoltage,
    ecology?.shortName === 'Landschaftsschutz' && styles.attributionEcologyLandscapeProtection,
    ecology?.shortName === 'Naturschutz' && styles.attributionEcologySpeciesProtection
  );

  return (
    <section className={styles.container}>
      <div className={styles.headerContainer}>
        {view !== ViewIds.EcologyHighVoltageSupplyLines && isDesktop && (
          <InfoOverlay
            infoContent="Klicken Sie auf einzelne Schutzgebiete, um Namen und Gebietstyp anzeigen
        zu lassen."
          />
        )}
        <div
          className={clsx(
            (!showLegend || !Ecologies.has(view)) && styles.hideHeader
          )}>
          <div className={styles.ecology}>
            <div>{ecology && ecology.name}</div>
            <div className={clsx(styles.description, styles.colorGrey)}>
              {ecology ? <EcologyLegend layers={ecology.data.layers} /> : null}
            </div>
            <Button
              variant="icon"
              className={styles.legendCloseButton}
              onClick={(): void => {
                dispatch(closeLegend());
              }}>
              <CloseIcon className={styles.colorGrey} />
            </Button>
            <footer className={attributionClass}>
              &copy; {ecology && ecology.copyright}
            </footer>
          </div>
        </div>
      </div>
    </section>
  );
};

interface ILegendProps {
  layers: IEcologyLayerData[];
}

const EcologyLegend = ({layers}: ILegendProps): JSX.Element => (
  <dl className={styles.layerLegend}>
    {layers.map(({id, name, color}) =>
      color ? (
        <span key={id}>
          <dt className={styles.layerLegendColor}>
            <svg
              viewBox="0 0 14 14"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <rect fill={color} x="0" y="0" width="14" height="14" />
            </svg>
          </dt>
          <dd className={styles.layerLegendDescription}>{name}</dd>
        </span>
      ) : (
        <span key={id}>
          <dt className={styles.layerLegendIcon}>
            <img
              src={`https://projektcheck.gs.ils-geomonitoring.de/geoserver/projektcheck/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&wodth=20&height=20&layer=${id}`}
            />
          </dt>
          <dd className={styles.layerLegendDescription}>{name}</dd>
        </span>
      )
    )}
  </dl>
);

export default Ecology;
