import React, {useState, useEffect} from 'react'; // eslint-disable-line no-unused-vars, max-len

import colors from '../../constants/colors';

interface IProps {
  isochrones: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> | null;
  mapCanvas: google.maps.Map | null;
  show: boolean;
}

const Isochrones = ({
  mapCanvas,
  isochrones,
  show
}: IProps): JSX.Element | null => {
  /**
   * The polygons for the isochrones on the map
   */
  const [polygons, setPolygons] = useState<google.maps.Data.Feature[]>([]);

  /**
   * Initialize the map styling
   */
  const initializeStyles = (): google.maps.Data.StylingFunction => {
    return feature => {
      let color = colors.petrolBlue;

      if (feature.getProperty('travelMode') === 'BICYCLE') {
        color = colors.darkGreen;
      }

      if (feature.getProperty('travelMode') === 'WALK') {
        color = colors.lightRed;
      }

      return {
        fillColor: color,
        fillOpacity: 0.2,
        strokeColor: color,
        strokeWeight: 1,
        zIndex: 1
      };
    };
  };

  useEffect(() => {
    if (!mapCanvas) {
      return;
    }

    mapCanvas.data.setStyle({});
    polygons.forEach(polygon => mapCanvas.data.remove(polygon));
    mapCanvas.data.setStyle(initializeStyles());

    if (!isochrones || !show) {
      return;
    }

    setPolygons(mapCanvas.data.addGeoJson(isochrones));
  }, [mapCanvas, isochrones, show]);

  return null;
};

export default Isochrones;
