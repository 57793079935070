import React, {useState} from 'react';
import {IGraphDatum} from '../../../interfaces/graph-datum';
import {IYearValue} from '../../../interfaces/year-value';
import sizeme from 'react-sizeme';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Hint
} from 'react-vis';
import colors from '../../../constants/colors';

import Loading from '../../shared/loading/loading';

interface IProps {
  data: IYearValue[] | null;
  size: {width: number; height: number};
  print?: boolean;
}
interface IGraphData {
  minYear: number;
  maxYear: number;
  domain: [number, number];
  tickTotal: number;
  graph: IGraphDatum<number, number>[];
}

const getGRaphData = (data: IYearValue[] | null = []): IGraphData | null => {
  if (!data) {
    return null;
  }

  const minByYear: IYearValue = minBy(data, 'year') || data[0];
  const maxByYear: IYearValue = maxBy(data, 'year') || data[data.length - 1];
  const maxByValue: IYearValue = maxBy(data, 'value') || data[0];
  const roundTo = 100;
  const minDomain = 0;
  const maxDomain: number =
    Math.floor(maxByValue.value / roundTo + 1) * roundTo;

  return {
    minYear: minByYear.year,
    maxYear: maxByYear.year,
    domain: [minDomain, maxDomain],
    tickTotal: (maxDomain - minDomain) / roundTo,
    graph: data.map(yearValue => ({
      x: yearValue.year,
      y: yearValue.value
    }))
  };
};

/**
 * Line Chart for the workplace development
 */
const LineChart = ({data, size, print}: IProps): JSX.Element | null => {
  const [nearestValue, setNearestValue] = useState<IGraphDatum<
    number,
    number
  > | null>(null);

  const graphData = getGRaphData(data);

  if (!graphData) {
    return null;
  }

  /**
   * Remember the value that get’s hovered in the chart
   */
  const handleNearestX = (hoveredDatum: IGraphDatum<number, number>): void => {
    const year: number = hoveredDatum.x;
    const hoveredValue =
      graphData.graph.find(graphDatum => graphDatum.x === year) || null;

    setNearestValue(hoveredValue);
  };

  if (!data) {
    return <Loading />;
  }

  return (
    <div>
      <p className="intro">
        Schätzung der Zahl der Arbeitsplätze auf der Entwicklungsfläche (grobe
        Orientierungswerte)
      </p>

      <XYPlot
        width={print ? 750 : size.width}
        height={266}
        margin={{left: 70, right: print ? 50 : 20, top: 10, bottom: 40}}
        yDomain={graphData.domain}
        onMouseLeave={(): void => setNearestValue(null)}>
        <HorizontalGridLines tickTotal={graphData.tickTotal} />

        <LineSeries
          data={graphData.graph}
          color={colors.primaryColor}
          onNearestX={handleNearestX}
        />

        <XAxis
          tickTotal={graphData.maxYear - graphData.minYear}
          tickFormat={tick => Number(tick)}
        />

        <YAxis
          tickTotal={Math.min(graphData.tickTotal, 8)}
          tickFormat={tick => Number(tick).toLocaleString('de')}
        />

        <span className="visualization--y-axis-label-rotated">
          Arbeitsplätze
        </span>

        {nearestValue && (
          <Hint
            value={nearestValue}
            align={{
              horizontal: Hint.ALIGN.RIGHT,
              vertical: Hint.ALIGN.TOP
            }}>
            <div className="visualization__hint">
              <span className="visualization__hint__nearestValue">
                {Math.round(nearestValue.y)}
              </span>
              <span className="visualization__hint__year">
                {nearestValue.x}
              </span>
            </div>
          </Hint>
        )}
      </XYPlot>
    </div>
  );
};

export default sizeme()(LineChart);
